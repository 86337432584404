/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Users</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'users' }">Users</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Edit</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <p><br /></p>
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p><br /></p>
            </div>
          </div>
          <!-- End Loading -->

          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-8 mb-5">
                  <!-- Card About -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabUser" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('User')"
                            aria-expanded="false"
                            aria-controls="collapseUser"
                            data-toggle="collapse"
                            data-target="#collapseUser"
                          >
                            User
                            <span
                              id="iconToggleUser"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseUser"
                          class="collapse show"
                          aria-labelledby="TabUser"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1">First Name</label>
                              <input
                                class="form-control"
                                id="input1"
                                type="text"
                                v-model="row.first_name"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1">Last Name</label>
                              <input
                                class="form-control"
                                id="input1"
                                type="text"
                                v-model="row.last_name"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group">
                              <label for="input3">Email</label>
                              <input
                                class="form-control"
                                id="input3"
                                type="text"
                                v-model="row.email"
                              />
                            </div>
                            <!-- End Slug -->

                            <!-- Slug -->
                            <div class="form-group">
                              <label for="input4">Password</label>
                              <input
                                class="form-control"
                                id="input4"
                                type="password"
                                v-model="row.password"
                                placeholder="***************"
                                autocomplete="off"
                              />
                            </div>
                            <!-- End Slug -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->
                </div>
                <!-- ******* End Card ******** -->

                <!-- ******* SideNavbar ******** -->
                <div class="col-md-4 mb-5">
                  <!-- Nav Image -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordionNav" class="accordion">
                        <div id="NavImage" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Image')"
                            aria-expanded="false"
                            aria-controls="collapseNavImage"
                            data-toggle="collapse"
                            data-target="#collapseNavImage"
                          >
                            Featued Image
                            <span
                              id="iconToggleImage"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseNavImage"
                          class="collapse show"
                          aria-labelledby="NavImage"
                          data-parent="#accordionNav"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label>Image</label>
                              <img
                                v-if="row.preview"
                                :src="row.preview"
                                class="mb-2 custom-image"
                              />
                              <input
                                type="file"
                                class="form-control"
                                ref="myDropify"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Nav Image -->
                </div>
                <!-- ******* SideNavbar ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update User</span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";

export default {
  name: "edit-comp",
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        role_id: 1,
        status: 1,

        preview: "",
        base64Image: "",

        first_name: "",
        last_name: "",
        name: "",
        email: "",
        password: "",
      },

      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/users/" + this.$route.params.id,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.row.preview = res.data.row.image;
          this.row.base64Image = res.data.row.image;
          this.row.first_name = res.data.row.first_name;
          this.row.last_name = res.data.row.last_name;
          this.row.email = res.data.row.email;

          localStorage.setItem("user_image", res.data.row.image);
          localStorage.setItem("user_name", res.data.row.name);
          //this.row.country = res.data.row.country;
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/users/" + this.$route.params.id,
        method: "PUT",
        data: {
          role_id: 1,
          country: "",
          base64Image: this.row.base64Image,
          first_name: this.row.first_name,
          last_name: this.row.last_name,
          email: this.row.email,
          password: this.row.password,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "User Updated Successfully.",
          });

          this.fetchRow();
          //this.$router.push({ name: 'home'})
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.base64Image = e.target.result;
      };
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
