/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2" style="text-transform: capitalize">{{ type }}</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link
                  :to="{ name: 'online-applications', params: { type: type } }"
                  >{{ type }}</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">Show</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="spinner-grow mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Loading -->

          <form v-if="!pgLoading" enctype="multipart/form-data" class="h-100">
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Personal -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabPersonal" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Personal')"
                            aria-expanded="false"
                            aria-controls="collapsePersonal"
                            data-toggle="collapse"
                            data-target="#collapsePersonal"
                          >
                            Personal Information
                            <span
                              id="iconTogglePersonal"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapsePersonal"
                          class="collapse show"
                          aria-labelledby="TabPersonal"
                          data-parent="#accordion"
                        >
                          <div class="row col-12">
                            <div class="col-md-6 col-sm-12 pt-3">
                              <!-- Title -->
                              <div class="form-group">
                                <label for="input1">First Name</label>
                                <input
                                  class="form-control"
                                  id="input1"
                                  type="text"
                                  disabled=""
                                  v-model="row.first_name"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="input2">Middle Name</label>
                                <input
                                  class="form-control"
                                  id="input2"
                                  type="text"
                                  disabled=""
                                  v-model="row.middle_name"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="input3">Last Name</label>
                                <input
                                  class="form-control"
                                  id="input3"
                                  type="text"
                                  disabled=""
                                  v-model="row.last_name"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="input4">Full Name</label>
                                <input
                                  class="form-control"
                                  id="input4"
                                  type="text"
                                  disabled=""
                                  v-model="row.full_name"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="inputv">Video Url</label>
                                <input
                                  class="form-control"
                                  id="inputv"
                                  type="text"
                                  disabled=""
                                  v-model="row.video_url"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="inputvscr">Reefer</label>
                                <input
                                  class="form-control"
                                  id="inputvscr"
                                  type="text"
                                  disabled=""
                                  v-model="row.reefer"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <!-- <div class="form-group">
                                        <label for="inputvsc">Sector Name</label>
                                        <input class="form-control" 
                                                id="inputvsc" 
                                                type="text" 
                                                disabled="" 
                                                v-model="row.sector">
                                    </div> -->
                              <!-- End Title -->
                              <!-- Title -->
                              <div class="form-group">
                                <label for="inputvs">Program Name</label>
                                <input
                                  class="form-control"
                                  id="inputvs"
                                  type="text"
                                  disabled=""
                                  v-model="row.program"
                                />
                              </div>
                              <!-- End Title -->
                            </div>

                            <div class="col-md-6 col-sm-12 pt-3">
                              <!-- Title -->
                              <div class="form-group">
                                <label for="input5">Nationality</label>
                                <input
                                  class="form-control"
                                  id="input5"
                                  type="text"
                                  disabled=""
                                  v-model="row.nationality"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="input6">Residential Address</label>
                                <input
                                  class="form-control"
                                  id="input6"
                                  type="text"
                                  disabled=""
                                  v-model="row.residential_address"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="input7">Telephone No</label>
                                <input
                                  class="form-control"
                                  id="input7"
                                  type="text"
                                  disabled=""
                                  v-model="row.telephone_no"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="input8">E-mail Address</label>
                                <input
                                  class="form-control"
                                  id="input8"
                                  type="text"
                                  disabled=""
                                  v-model="row.email_Address"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div v-if="row.image" class="form-group">
                                <label for="inputI">Image</label>
                                <p>
                                  <img :src="row.image" style="width: 100px" />
                                </p>
                                <p>
                                  <a :href="row.image" target="_blacnk"
                                    >Preview Full screen
                                  </a>
                                </p>
                              </div>
                              <!-- End Title -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Personal -->

                  <!-- Card Document -->
                  <div
                    class="card mt-5"
                    v-if="type != 'trainings-applications'"
                  >
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabDocument" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Document')"
                            aria-expanded="false"
                            aria-controls="collapseDocument"
                            data-toggle="collapse"
                            data-target="#collapseDocument"
                          >
                            Documents
                            <span
                              id="iconToggleDocument"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseDocument"
                          class="collapse"
                          aria-labelledby="TabDocument"
                          data-parent="#accordion"
                        >
                          <div class="row col-12">
                            <div class="col-md-6 col-sm-12 pt-3">
                              <!-- Title -->
                              <div class="form-group">
                                <label for="doc1">ID or passport copy</label>
                                &nbsp;&nbsp;<a
                                  v-if="file.passport_file"
                                  :href="file.passport_file"
                                  target="_blank"
                                  >Preview
                                </a>
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="doc2"
                                  >Current occupation (documented)</label
                                >
                                &nbsp;&nbsp;<a
                                  v-if="file.occupation_file"
                                  :href="file.occupation_file"
                                  target="_blank"
                                  >Preview
                                </a>
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="doc3"
                                  >Proof of prior work experience (via HR
                                  letters)</label
                                >
                                &nbsp;&nbsp;<a
                                  v-if="file.hr_letter_file"
                                  :href="file.hr_letter_file"
                                  target="_blank"
                                  >Preview
                                </a>
                              </div>
                              <!-- End Title -->
                            </div>

                            <div class="col-md-6 col-sm-12 pt-3">
                              <!-- Title -->
                              <div class="form-group">
                                <label for="doc4">Passport size photos!</label>
                                &nbsp;&nbsp;<a
                                  v-if="file.passport_size_file"
                                  :href="file.passport_size_file"
                                  target="_blank"
                                  >Preview
                                </a>
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group">
                                <label for="doc5">Detailed resume</label>
                                &nbsp;&nbsp;<a
                                  v-if="file.detailed_resume"
                                  :href="file.detailed_resume"
                                  target="_blank"
                                  >Preview
                                </a>
                              </div>
                              <!-- End Title -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Document -->

                  <!-- Card Academic -->
                  <div
                    class="card mt-5"
                    v-if="type != 'trainings-applications'"
                  >
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabAcademic" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Academic')"
                            aria-expanded="false"
                            aria-controls="collapseAcademic"
                            data-toggle="collapse"
                            data-target="#collapseAcademic"
                          >
                            Academic Qualifactions
                            <span
                              id="iconToggleAcademic"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseAcademic"
                          class="collapse"
                          aria-labelledby="TabAcademic"
                          data-parent="#accordion"
                        >
                          <div
                            class="col-12 row pt-3"
                            v-for="(qa, index) in qualifications"
                            :key="index"
                          >
                            <!-- Title -->
                            <div class="form-group col-6">
                              <label for="aca1"
                                >Educational and Qualification</label
                              >
                              <input
                                class="form-control"
                                id="aca1"
                                type="text"
                                disabled=""
                                v-model="qa.educational"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Title -->
                            <div class="form-group col-3">
                              <label for="aca2">University / College</label>
                              <input
                                class="form-control"
                                id="aca2"
                                type="text"
                                disabled=""
                                v-model="qa.univeristy"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Title -->
                            <div class="form-group col-2">
                              <label for="aca3">GPA / Grade</label>
                              <input
                                class="form-control"
                                id="aca3"
                                type="text"
                                disabled=""
                                v-model="qa.grade"
                              />
                            </div>
                            <!-- End Title -->
                            <!-- Title -->
                            <div class="form-group col-1">
                              <label for="aca4">Year</label>
                              <input
                                class="form-control"
                                id="aca1"
                                type="text"
                                disabled=""
                                v-model="qa.year"
                              />
                            </div>
                            <!-- End Title -->

                            <div class="form-group col-6">
                              <label for="oc4">Certificate</label>
                              <p v-if="qa.certificate">
                                &nbsp;&nbsp;
                                <a :href="qa.certificate" target="_blank"
                                  >Preview
                                </a>
                              </p>
                              <p v-else><a>No certificate found.</a></p>
                            </div>

                            <!-- Title -->
                            <div class="form-group col-3">
                              <label for="oc4">Date From</label>
                              <input
                                class="form-control"
                                id="oc1"
                                type="text"
                                disabled=""
                                v-model="qa.date_from"
                              />
                            </div>
                            <!-- End Title -->
                            <!-- Title -->
                            <div class="form-group col-3">
                              <label for="oc4">Date To</label>
                              <input
                                class="form-control"
                                id="oc1"
                                type="text"
                                disabled=""
                                v-model="qa.date_to"
                              />
                            </div>
                            <!-- End Title -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Document -->

                  <!-- Card Courses -->
                  <div
                    class="card mt-5"
                    v-if="type != 'trainings-applications'"
                  >
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabCourses" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Courses')"
                            aria-expanded="false"
                            aria-controls="collapseCourses"
                            data-toggle="collapse"
                            data-target="#collapseCourses"
                          >
                            Courses Attended
                            <span
                              id="iconToggleCourses"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseCourses"
                          class="collapse"
                          aria-labelledby="TabCourses"
                          data-parent="#accordion"
                        >
                          <div
                            class="col-12 row pt-3"
                            v-for="(co, index) in courses"
                            :key="index"
                          >
                            <!-- Title -->
                            <div class="form-group col-6">
                              <label for="oc1">Courses Attended</label>
                              <input
                                class="form-control"
                                id="oc1"
                                type="text"
                                disabled=""
                                v-model="co.program"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Title -->
                            <div class="form-group col-3">
                              <label for="oc2"
                                >Institute / Training center</label
                              >
                              <input
                                class="form-control"
                                id="oc2"
                                type="text"
                                disabled=""
                                v-model="co.institute"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Title -->
                            <div class="form-group col-3">
                              <label for="oc3">Duration (Hours)</label>
                              <input
                                class="form-control"
                                id="oc3"
                                type="text"
                                disabled=""
                                v-model="co.duration"
                              />
                            </div>
                            <!-- End Title -->
                            <!-- Title -->
                            <div class="form-group col-6">
                              <label for="oc4">Certificate</label>
                              <p v-if="co.certificate">
                                &nbsp;&nbsp;
                                <a
                                  :href="
                                    'https://api.agmep.net/uploads/' +
                                    co.certificate
                                  "
                                  target="_blank"
                                  >Preview
                                </a>
                              </p>
                              <p v-else><a>No certificate found.</a></p>
                            </div>
                            <!-- End Title -->
                            <!-- Title -->
                            <div class="form-group col-3">
                              <label for="oc4">Date From</label>
                              <input
                                class="form-control"
                                id="oc1"
                                type="text"
                                disabled=""
                                v-model="co.date_from"
                              />
                            </div>
                            <!-- End Title -->
                            <!-- Title -->
                            <div class="form-group col-3">
                              <label for="oc4">Date To</label>
                              <input
                                class="form-control"
                                id="oc1"
                                type="text"
                                disabled=""
                                v-model="co.date_to"
                              />
                            </div>
                            <!-- End Title -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Crouses -->

                  <!-- Card Langauges -->
                  <div
                    class="card mt-5"
                    v-if="type != 'trainings-applications'"
                  >
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabLangauges" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Langauges')"
                            aria-expanded="false"
                            aria-controls="collapseLangauges"
                            data-toggle="collapse"
                            data-target="#collapseLangauges"
                          >
                            Languages
                            <span
                              id="iconToggleLangauges"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseLangauges"
                          class="collapse"
                          aria-labelledby="TabLangauges"
                          data-parent="#accordion"
                        >
                          <div
                            class="col-12 row pt-3"
                            v-for="(la, index) in languages"
                            :key="index"
                          >
                            <!-- Title -->
                            <div class="form-group col-md-6 col-sm-12">
                              <label for="la1">Langauge</label>
                              <input
                                class="form-control"
                                id="la1"
                                type="text"
                                disabled=""
                                v-model="la.language"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Title -->
                            <div class="form-group col-md-6 col-sm-12">
                              <label for="la2">Level of Proficiency</label>
                              <input
                                class="form-control"
                                id="la2"
                                type="text"
                                disabled=""
                                v-model="la.level"
                              />
                            </div>
                            <!-- End Title -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Langauges -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";

export default {
  name: "show-comp",
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      file: {
        passport_file: "",
        passport_file_size: "",
        occupation_file: "",
        detailed_resume: "",
        hr_letter_file: "",
      },
      row: {
        image: "",
        full_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        nationality: "",
        residential_address: "",
        telephone_no: "",
        email_Address: "",
        video_url: "",

        pname: "",
        program: "",
        sector: "",
        reefer: "",
      },

      type: "",
      courses: [],
      languages: [],
      qualifications: [],
      pgLoading: true,
    };
  },
  mounted() {},
  computed: {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (this.$route.params.type) {
      this.type = this.$route.params.type;
    }

    this.fetchRow();
  },
  methods: {
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url:
          window.baseURL +
          "/applications/" +
          this.type +
          "/" +
          this.$route.params.id,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.file.passport_file = res.data.row.passport_file;
          this.file.passport_size_file = res.data.row.passport_size_file;
          this.file.occupation_file = res.data.row.occupation_file;
          this.file.detailed_resume = res.data.row.detailed_resume;
          this.file.hr_letter_file = res.data.row.hr_letter_file;

          this.row.image = res.data.row.image;
          this.row.first_name = res.data.row.first_name;
          this.row.middle_name = res.data.row.middle_name;
          this.row.last_name = res.data.row.last_name;
          this.row.full_name = res.data.row.full_name;
          this.row.nationality = res.data.row.nationality;
          this.row.residential_address = res.data.row.residential_address;
          this.row.telephone_no = res.data.row.telephone_no;
          this.row.email_Address = res.data.row.email_Address;
          this.row.video_url = res.data.row.video_url;

          this.row.program = res.data.row.program;
          this.row.sector = res.data.row.sector;
          this.row.reefer = res.data.row.reefer;

          this.courses = res.data.row.courses;
          this.languages = res.data.row.languages;
          this.qualifications = res.data.row.qualifications;
        })
        .catch((err) => {
          this.pgLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({
          name: "online-applications",
          params: { type: this.type },
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
