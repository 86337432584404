var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('aside',{staticClass:"u-sidebar",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"u-sidebar-inner"},[_c('header',{staticClass:"u-sidebar-header"},[_c('router-link',{staticClass:"u-sidebar-logo",attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"u-header-logo__icon",staticStyle:{"height":"37px"},attrs:{"src":"/assets/img/dashboard-icon.png","alt":""}}),_c('img',{staticClass:"u-header-logo__text",staticStyle:{"height":"28px"},attrs:{"src":"/assets/svg/dashboard-txt.svg","alt":""}})])],1),_c('nav',{staticClass:"u-sidebar-nav"},[_c('ul',{staticClass:"u-sidebar-nav-menu u-sidebar-nav-menu--top-level",on:{"click":_vm.opnLi}},[_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/' ? 'active' : '',attrs:{"to":{ name: 'dashboard' }}},[_c('span',{staticClass:"ti-dashboard u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Dashboard")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/home' ? 'active' : '',attrs:{"to":{ name: 'home' }}},[_c('span',{staticClass:"ti-home u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Home")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/popular-searchs' ||
                this.$route.path == '/popular-searchs/status/active' ||
                this.$route.path == '/popular-searchs/status/inactive' ||
                this.$route.path == '/popular-searchs/status/trash' ||
                this.$route.path == '/popular-searchs/create'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'searchs' }}},[_c('span',{staticClass:"ti-search u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Popular Searchs")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path ==
                  '/online-applications/trainings-applications' ||
                this.$route.path ==
                  '/online-applications/memberships-applications' ||
                this.$route.path ==
                  '/online-applications/instructor-applications' ||
                this.$route.path ==
                  '/online-applications/experience-applications' ||
                this.$route.path ==
                  '/online-applications/accreditation-applications' ||
                this.$route.path ==
                  '/online-applications/certificate-applications'
                  ? 'active'
                  : '',attrs:{"to":{
                name: 'online-applications',
                params: { type: 'trainings-applications' },
              }}},[_c('span',{staticClass:"ti-medall u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Online Applications")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/accreditations' ||
                this.$route.path == '/accreditations/status/active' ||
                this.$route.path == '/accreditations/status/inactive' ||
                this.$route.path == '/accreditations/status/trash'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'accreditations' }}},[_c('span',{staticClass:"ti-medall u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Accreditations")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/programs' ||
                this.$route.path == '/programs/status/active' ||
                this.$route.path == '/programs/status/inactive' ||
                this.$route.path == '/programs/status/trash' ||
                this.$route.path == '/programs/create'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'programs' }}},[_c('span',{staticClass:"ti-pencil-alt u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Programs")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/certificates' ? 'active' : '',attrs:{"to":{ name: 'certificates' }}},[_c('span',{staticClass:"ti-file u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Our Certifications")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/memberships' ||
                this.$route.path == '/memberships/status/published' ||
                this.$route.path == '/memberships/status/draft' ||
                this.$route.path == '/memberships/status/trash' ||
                this.$route.path == '/memberships/create'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'memberships' }}},[_c('span',{staticClass:"ti-id-badge u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Memberships")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/about' ? 'active' : '',attrs:{"to":{ name: 'about' }}},[_c('span',{staticClass:"ti-help-alt u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("About")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/contacts' ? 'active' : '',attrs:{"to":{ name: 'contacts' }}},[_c('span',{staticClass:"ti-headphone-alt u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Contacts")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/inbox' ||
                this.$route.path == '/inbox/status/seen' ||
                this.$route.path == '/inbox/status/unseen' ||
                this.$route.path == '/inbox/status/trash'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'inbox' }}},[_c('span',{staticClass:"ti-email u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Inbox")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/media' ? 'active' : '',attrs:{"to":{ name: 'media' }}},[_c('span',{staticClass:"ti-cloud-up u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Media")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/pages' ||
                this.$route.path == '/pages/status/active' ||
                this.$route.path == '/pages/status/inactive' ||
                this.$route.path == '/pages/create'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'pages' }}},[_c('span',{staticClass:"ti-files u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Pages")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/users' ||
                this.$route.path == '/users/status/seen' ||
                this.$route.path == '/users/status/unseen' ||
                this.$route.path == '/users/status/trash'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'users' }}},[_c('span',{staticClass:"ti-user u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Users")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/faqs' ||
                this.$route.path == '/faqs/status/active' ||
                this.$route.path == '/faqs/status/inactive' ||
                this.$route.path == '/faqs/status/trash' ||
                this.$route.path == '/faqs/create'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'faqs' }}},[_c('span',{staticClass:"ti-info-alt u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("FAQs")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/privacy-policy' ||
                this.$route.path == '/privacy-policy/status/active' ||
                this.$route.path == '/privacy-policy/status/inactive' ||
                this.$route.path == '/privacy-policy/status/trash' ||
                this.$route.path == '/privacy-policy/create'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'privacy' }}},[_c('span',{staticClass:"ti-receipt u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Privacy Policy")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/events-conferences' ? 'active' : '',attrs:{"to":{ name: 'events' }}},[_c('span',{staticClass:"ti-flag-alt u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Events & Conferences")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/online-training' ||
                this.$route.path == '/online-training/status/active' ||
                this.$route.path == '/online-training/status/inactive' ||
                this.$route.path == '/online-training/status/trash' ||
                this.$route.path == '/online-training/create'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'online' }}},[_c('span',{staticClass:"ti-paint-roller u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Online Trainings")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/socials' ||
                this.$route.path == '/socials/status/active' ||
                this.$route.path == '/socials/status/inactive' ||
                this.$route.path == '/socials/status/trash'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'socials' }}},[_c('span',{staticClass:"ti-twitter u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Social Networks")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/subscribers' ||
                this.$route.path == '/subscribers/status/seen' ||
                this.$route.path == '/subscribers/status/unseen' ||
                this.$route.path == '/subscribers/status/trash'
                  ? 'active'
                  : '',attrs:{"to":{ name: 'subscribers' }}},[_c('span',{staticClass:"ti-map u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Subscribers")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/settings' ? 'active' : '',attrs:{"to":{ name: 'settings' }}},[_c('span',{staticClass:"ti-settings u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Settings")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"},[_c('router-link',{staticClass:"u-sidebar-nav-menu__link",class:this.$route.path == '/email-templates' ? 'active' : '',attrs:{"to":{ name: 'emails' }}},[_c('span',{staticClass:"ti-email u-sidebar-nav-menu__item-icon"}),_c('span',{staticClass:"u-sidebar-nav-menu__item-title"},[_vm._v("Email Templates")])])],1),_c('li',{staticClass:"u-sidebar-nav-menu__item"}),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }