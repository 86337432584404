import { render, staticRenderFns } from "./Show.vue?vue&type=template&id=4d6d99f0&scoped=true"
import script from "./Show.vue?vue&type=script&lang=js"
export * from "./Show.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d6d99f0",
  null
  
)

export default component.exports