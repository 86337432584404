/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2" style="text-transform: capitalize">{{ type }}</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link
                  :to="{ name: 'online-applications', params: { type: type } }"
                  >{{ type }}</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">Update</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="spinner-grow mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Loading -->

          <!-- Form -->
          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <!-- Card Accredittaions -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabMask" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Mask')"
                            aria-expanded="false"
                            aria-controls="collapseMask"
                            data-toggle="collapse"
                            data-target="#collapseMask"
                          >
                            Mask
                            <span
                              id="iconToggleMask"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseMask"
                          class="collapse"
                          aria-labelledby="TabMask"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3 row">
                            <div class="form-group col-10">
                              <!-- Title -->
                              <div class="form-group col-12">
                                <label for="editor00x1">bg Title</label>
                                <vue-editor
                                  id="editor00x1"
                                  v-model="row.bgTitle"
                                />
                              </div>
                              <div class="form-group col-12">
                                <label for="editor00x2">bg SubTitle</label>
                                <vue-editor
                                  id="editor00x2"
                                  v-model="row.bgSubTitle"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <!-- End Title -->
                            </div>

                            <!-- Slug -->
                            <div class="form-group col-2">
                              <label for="input2">bg Color</label>
                              <input
                                class="form-control"
                                id="input2"
                                type="color"
                                style="height: 200px"
                                v-model="row.bgColor"
                              />
                            </div>
                            <!-- End Slug -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Step 1
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <vue-editor id="editor1" v-model="row.text1" />
                            </div>
                            <div class="row">
                              <div class="form-group col-6">
                                <vue-editor id="editor2" v-model="row.text2" />
                              </div>
                              <div class="form-group col-6">
                                <vue-editor id="editor3" v-model="row.text3" />
                              </div>
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="row">
                              <div class="form-group col-6">
                                <vue-editor
                                  id="pdf1"
                                  v-model="row.pdf1"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <div class="form-group col-6">
                                <vue-editor
                                  id="pdf2"
                                  v-model="row.pdf2"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <div class="form-group col-6">
                                <vue-editor
                                  id="pdf3"
                                  v-model="row.pdf3"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <div class="form-group col-6">
                                <vue-editor
                                  id="pdf4"
                                  v-model="row.pdf4"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <div class="form-group col-6">
                                <vue-editor
                                  id="pdf5"
                                  v-model="row.pdf5"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <div class="form-group col-6">
                                <vue-editor
                                  id="pdf6"
                                  v-model="row.pdf6"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <vue-editor id="editor4" v-model="row.text4" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="Step2" class="accordion">
                        <div id="TabStep2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Step2')"
                            aria-expanded="false"
                            aria-controls="collapseStep2"
                            data-toggle="collapse"
                            data-target="#collapseStep2"
                          >
                            Step 2
                            <span
                              id="iconToggleStep2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseStep2"
                          class="collapse"
                          aria-labelledby="TabStep2"
                          data-parent="#Step2"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general1_title"
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general1_body"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general2_title"
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general2_body"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general3_title"
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general3_body"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general4_title"
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general4_body"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general5_title"
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.general5_body"
                              />
                            </div>

                            <br /><br /><br />

                            <div class="form-group">
                              <vue-editor id="note1" v-model="row.note1" />
                            </div>

                            <div class="form-group">
                              <vue-editor id="note2" v-model="row.note2" />
                            </div>

                            <div class="form-group">
                              <vue-editor id="text5" v-model="row.text5" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="Step3" class="accordion">
                        <div id="TabStep3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Step3')"
                            aria-expanded="false"
                            aria-controls="collapseStep3"
                            data-toggle="collapse"
                            data-target="#collapseStep3"
                          >
                            Step 3
                            <span
                              id="iconToggleStep3"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseStep3"
                          class="collapse"
                          aria-labelledby="TabStep3"
                          data-parent="#Step3"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <vue-editor
                                id="authority_note"
                                v-model="row.authority_note"
                              />
                            </div>

                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.authority_title"
                              />
                            </div>
                            <br /><br />

                            <div class="form-group">
                              <vue-editor
                                id="authority1"
                                v-model="row.authority1"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <vue-editor
                                id="authority2"
                                v-model="row.authority2"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <vue-editor
                                id="authority3"
                                v-model="row.authority3"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <vue-editor
                                id="authority4"
                                v-model="row.authority4"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <vue-editor
                                id="authority5"
                                v-model="row.authority5"
                              />
                            </div>

                            <br />
                            <hr />
                            <br />

                            <div class="form-group">
                              <vue-editor
                                id="authority6"
                                v-model="row.authority6"
                              />
                            </div>

                            <br /><br /><br />

                            <div class="form-group">
                              <vue-editor id="head" v-model="row.head" />
                            </div>

                            <div class="form-group">
                              <vue-editor
                                id="last_confirm"
                                v-model="row.last_confirm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Application</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
          </form>
          <!-- End Form -->
        </div>
        <!-- End Content Body -->

        <Footer></Footer>
      </div>
      <!-- Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  name: "List",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      editor: VueEditor,
      content: "<h1>Write your content here!</h1>",
      auth: {
        access_token: "",
      },
      row: {
        preview: "",
        image: "",
        bgTitle: "",
        bgSubTitle: "",
        bgColor: "",

        // Step 1
        text1: "",
        text2: "",
        text3: "",

        pdf1: "",
        pdf2: "",
        pdf3: "",
        pdf4: "",
        pdf5: "",
        pdf6: "",

        text4: "",

        // Step 2
        general1_title: "",
        general1_body: "",

        general2_title: "",
        general2_body: "",

        general3_title: "",
        general3_body: "",

        general4_title: "",
        general4_body: "",

        general5_title: "",
        general5_body: "",

        note1: "",
        note2: "",

        text5: "",

        // Step 3
        authority_note: "",
        authority_title: "",
        authority1: "",
        authority2: "",
        authority3: "",
        authority4: "",
        authority5: "",
        authority6: "",

        head: "",
        last_confirm: "",
      },

      type: "",
      type_id: 1,
      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    if (this.$route.params.type) {
      this.type = this.$route.params.type;

      if (this.$route.params.type == "accreditation-applications") {
        this.type_id = 1;
      } else {
        this.type_id = 2;
      }
    }
    this.fetchRow();
  },
  methods: {
    // fetchRow
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/modern-app/" + this.type_id,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.preview = res.data.row.image;

          this.row.bgTitle = res.data.row.bgTitle;
          this.row.bgSubTitle = res.data.row.bgSubTitle;
          this.row.bgColor = res.data.row.bgColor;

          // step 1
          this.row.text1 = res.data.row.text1;
          this.row.text2 = res.data.row.text2;
          this.row.text3 = res.data.row.text3;

          this.row.pdf1 = res.data.row.pdf1;
          this.row.pdf2 = res.data.row.pdf2;
          this.row.pdf3 = res.data.row.pdf3;
          this.row.pdf4 = res.data.row.pdf4;
          this.row.pdf5 = res.data.row.pdf5;
          this.row.pdf6 = res.data.row.pdf6;

          this.row.text4 = res.data.row.text4;

          // step 2
          this.row.general1_title = res.data.row.general1_title;
          this.row.general1_body = res.data.row.general1_body;
          this.row.general2_title = res.data.row.general2_title;
          this.row.general2_body = res.data.row.general2_body;
          this.row.general3_title = res.data.row.general3_title;
          this.row.general3_body = res.data.row.general3_body;
          this.row.general4_title = res.data.row.general4_title;
          this.row.general4_body = res.data.row.general4_body;
          this.row.general5_title = res.data.row.general5_title;
          this.row.general5_body = res.data.row.general5_body;

          this.row.note1 = res.data.row.note1;
          this.row.note2 = res.data.row.note2;

          this.row.text5 = res.data.row.text5;

          // Step 3
          this.row.authority_note = res.data.row.authority_note;
          this.row.authority_title = res.data.row.authority_title;
          this.row.authority1 = res.data.row.authority1;
          this.row.authority2 = res.data.row.authority2;
          this.row.authority3 = res.data.row.authority3;
          this.row.authority4 = res.data.row.authority4;
          this.row.authority5 = res.data.row.authority5;
          this.row.authority6 = res.data.row.authority6;

          this.row.head = res.data.row.head;
          this.row.last_confirm = res.data.row.last_confirm;
        })
        .catch(() => {})
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/modern-app/" + this.type_id,
        method: "PUT",
        data: {
          image: this.row.image,
          bgTitle: this.row.bgTitle,
          bgSubTitle: this.row.bgSubTitle,
          bgColor: this.row.bgColor,

          text1: this.row.text1,
          text2: this.row.text2,
          text3: this.row.text3,

          pdf1: this.row.pdf1,
          pdf2: this.row.pdf2,
          pdf3: this.row.pdf3,
          pdf4: this.row.pdf4,
          pdf5: this.row.pdf5,
          pdf6: this.row.pdf6,

          text4: this.row.text4,

          // Step 2
          general1_title: this.row.general1_title,
          general1_body: this.row.general1_body,
          general2_title: this.row.general2_title,
          general2_body: this.row.general2_body,
          general3_title: this.row.general3_title,
          general3_body: this.row.general3_body,
          general4_title: this.row.general4_title,
          general4_body: this.row.general4_body,
          general5_title: this.row.general5_title,
          general5_body: this.row.general5_body,

          note1: this.row.note1,
          note2: this.row.note2,

          text5: this.row.text5,

          // Step 3
          authority_note: this.row.authority_note,
          authority_title: this.row.authority_title,
          authority1: this.row.authority1,
          authority2: this.row.authority2,
          authority3: this.row.authority3,
          authority4: this.row.authority4,
          authority5: this.row.authority5,
          authority6: this.row.authority6,

          head: this.row.head,
          last_confirm: this.row.last_confirm,
        },
      };
      axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Application Updated Successfully.",
          });
          this.$router.push({
            name: "online-applications",
            params: { type: this.type },
          });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({
          name: "online-applications",
          params: { type: this.type },
        });
      }
    },
  },
};
</script>
