/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Accreditations</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'accreditations' }"
                  >Accreditations</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add New
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <form
            @submit.prevent="addNew"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                accept="image/*"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <!-- Card Mask -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabMask" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Mask')"
                            aria-expanded="false"
                            aria-controls="collapseMask"
                            data-toggle="collapse"
                            data-target="#collapseMask"
                          >
                            Mask
                            <span
                              id="iconToggleMask"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseMask"
                          class="collapse"
                          aria-labelledby="TabMask"
                          data-parent="#accordion"
                        >
                          <div class="col-12 row pt-3">
                            <!-- Title -->
                            <div class="form-group col-9">
                              <label for="input1">background Title</label>
                              <vue-editor
                                id="editor01"
                                v-model="row.bgTitle"
                                :api-key="editor.api_key"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group col-3">
                              <label for="input2">background Color</label>
                              <input
                                class="form-control"
                                id="input2"
                                type="color"
                                style="height: 200px"
                                v-model="row.bgColor"
                              />
                            </div>
                            <!-- End Slug -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Mask -->

                  <!-- Card  -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Page Name
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1">Title</label>
                              <input
                                class="form-control"
                                id="input1"
                                type="text"
                                v-model="row.title"
                                @keyup="onTitleChange"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group">
                              <label for="input2">Slug</label>
                              <input
                                class="form-control text-lowercase"
                                id="input2"
                                type="text"
                                v-model="row.slug"
                                @keydown.space.prevent
                                @paste="onSlugPaste"
                                @change="onSlugChange(false)"
                              />
                            </div>
                            <!-- End Slug -->

                            <!-- Sort -->
                            <div class="form-group">
                              <label for="input3">Sort</label>
                              <input
                                class="form-control"
                                id="input3"
                                min="0"
                                type="number"
                                v-model.number="row.sort"
                              />
                            </div>
                            <!-- End Sort -->

                            <h4><br />Status & Visibility</h4>
                            <div class="col-md-12">
                              <!-- Status -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    :checked="row.status"
                                    @click="onStatus"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch1"
                                    v-html="row.status ? 'Active' : 'Inactive'"
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Status -->

                              <!-- Training -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch2"
                                    :checked="row.has_application"
                                    @click="onApplication"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch2"
                                    v-html="
                                      row.has_application
                                        ? 'Show Application'
                                        : 'Hide Application'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Training -->

                              <div
                                class="col-12 row"
                                v-if="row.has_application"
                              >
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Application Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.application_name"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Application Type</label>
                                  <select
                                    class="form-control"
                                    v-model="row.application_path"
                                  >
                                    <option value="trainings-applications">
                                      Training Application
                                    </option>
                                    <option value="members-applications">
                                      Membership Application
                                    </option>
                                    <option value="instructor-applications">
                                      Iam Instructor Application
                                    </option>
                                    <option value="experience-applications">
                                      I've Experience Application
                                    </option>
                                    <option value="accreditation-applications">
                                      Accreditation Application
                                    </option>
                                    <option value="certificate-applications">
                                      Certificate Application
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <!-- Faq -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch3"
                                    :checked="row.has_faq"
                                    @click="onFaq"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch3"
                                    v-html="
                                      row.has_faq ? 'Show FAQ' : 'Hide FAQ'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Faq -->

                              <div class="col-12 row" v-if="row.has_faq">
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>FAQ Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.faq_link"
                                  />
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch33"
                                    :checked="row.has_payment"
                                    @click="onPayment"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch33"
                                    v-html="
                                      row.has_payment
                                        ? 'Show Payment'
                                        : 'Hide Payment'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 row" v-if="row.has_payment">
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Payment Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.payment_name"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Payment Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.payment_link"
                                  />
                                </div>
                              </div>

                              <!-- Faq -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch4"
                                    :checked="row.has_download"
                                    @click="onDownload"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch4"
                                    v-html="
                                      row.has_download
                                        ? 'Show Download File'
                                        : 'Hide Download File'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Faq -->

                              <div class="col-12 row" v-if="row.has_download">
                                <div class="form-group col-8">
                                  <label>Download Title</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.download_name"
                                  />
                                </div>
                                <div class="form-group col-4">
                                  <label
                                    >Download File
                                    <button
                                      v-if="row.download_preview"
                                      type="button"
                                      class="btn btn-danger btn-sm btn-pill"
                                      style="
                                        margin-top: -10px;
                                        margin-left: 10px;
                                      "
                                      @click="
                                        row.download_preview = '';
                                        row.download_file = '';
                                      "
                                    >
                                      Remove File
                                    </button>
                                  </label>
                                  <a
                                    v-if="row.download_preview"
                                    :href="row.download_preview"
                                    target="_blank"
                                    class="pull-right"
                                    >Preview
                                  </a>
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="onFileChange"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label
                                    >Download Image 180 * 220
                                    <button
                                      v-if="row.download_image_preview"
                                      type="button"
                                      class="btn btn-danger btn-sm btn-pill"
                                      style="
                                        margin-top: -10px;
                                        margin-left: 10px;
                                      "
                                      @click="
                                        row.download_image_preview = '';
                                        row.download_image = '';
                                      "
                                    >
                                      Remove Image
                                    </button>
                                  </label>
                                  <p>
                                    <img
                                      v-if="row.download_image_preview"
                                      :src="row.download_image_preview"
                                      style="max-width: 50%"
                                    />
                                  </p>
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="onFilePreviewChange"
                                    accept="image/*"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End -->

                  <!-- Card Desgin 1 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent1" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content1')"
                            aria-expanded="false"
                            aria-controls="collapseContent1"
                            data-toggle="collapse"
                            data-target="#collapseContent1"
                          >
                            Group 1
                            <span
                              id="iconToggleContent1"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent1"
                          class="collapse"
                          aria-labelledby="TabContent1"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <vue-editor
                                id="editorD1"
                                v-model="row.body1_1"
                                :api-key="editor.api_key"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 1 -->

                  <!-- Card Desgin 2 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content2')"
                            aria-expanded="false"
                            aria-controls="collapseContent2"
                            data-toggle="collapse"
                            data-target="#collapseContent2"
                          >
                            Group 2
                            <span
                              id="iconToggleContent2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent2"
                          class="collapse"
                          aria-labelledby="TabContent2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <vue-editor
                                id="editor1D2"
                                v-model="row.body2_1"
                                :api-key="editor.api_key"
                              />
                            </div>

                            <div
                              class="col-12 row"
                              style="padding: 0; margin-left: unset"
                            >
                              <div
                                class="form-group col-4"
                                style="padding-left: 0"
                              >
                                <vue-editor
                                  id="editor2D2"
                                  v-model="row.body2_2"
                                  :api-key="editor.api_key"
                                />
                              </div>

                              <div class="form-group col-4">
                                <vue-editor
                                  id="editor3D2"
                                  v-model="row.body2_3"
                                  :api-key="editor.api_key"
                                />
                              </div>

                              <div
                                class="form-group col-4"
                                style="padding-right: 0"
                              >
                                <vue-editor
                                  id="editor4D2"
                                  v-model="row.body2_4"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <div class="form-group">
                              <vue-editor
                                id="editor5D2"
                                v-model="row.body2_5"
                                :api-key="editor.api_key"
                              />
                            </div>

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D2"
                                  v-model="row.body2_6"
                                  :api-key="editor.api_key"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_6"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_6 = '';
                                      row.image2_6 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_6 ? row.preview2_6 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_6Change"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_7"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_7 = '';
                                      row.image2_7 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_7 ? row.preview2_7 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_7Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor7D2"
                                  v-model="row.body2_7"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor8D2"
                                  v-model="row.body2_8"
                                  :api-key="editor.api_key"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_8"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_8 = '';
                                      row.image2_8 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_8 ? row.preview2_8 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_8Change"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_9"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_9 = '';
                                      row.image2_9 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_9 ? row.preview2_9 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_9Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor9D2"
                                  v-model="row.body2_9"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor10D2"
                                  v-model="row.body2_10"
                                  :api-key="editor.api_key"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_10"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_10 = '';
                                      row.image2_10 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_10 ? row.preview2_10 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_10Change"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_11"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_11 = '';
                                      row.image2_11 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_11 ? row.preview2_11 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_11Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor11D2"
                                  v-model="row.body2_11"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor12D2"
                                  v-model="row.body2_12"
                                  :api-key="editor.api_key"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_12"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_12 = '';
                                      row.image2_12 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_12 ? row.preview2_12 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_12Change"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_13"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_13 = '';
                                      row.image2_13 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_13 ? row.preview2_13 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_13Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor13D2"
                                  v-model="row.body2_13"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 2 -->

                  <!-- Card Desgin 3 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content3')"
                            aria-expanded="false"
                            aria-controls="collapseContent3"
                            data-toggle="collapse"
                            data-target="#collapseContent3"
                          >
                            Group 3
                            <span
                              id="iconToggleContent3"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent3"
                          class="collapse"
                          aria-labelledby="TabContent3"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <vue-editor
                                id="editorD3"
                                v-model="row.body3_1"
                                :api-key="editor.api_key"
                              />
                            </div>

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview3_2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_2 = '';
                                      row.image3_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_2 ? row.preview3_2 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_2Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line3_2"
                                />
                                <vue-editor
                                  id="editor1D323"
                                  v-model="row.mask3_2"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color3_2"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor2D3321"
                                  v-model="row.body3_2_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor3D3322"
                                  v-model="row.body3_2_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line3_3"
                                />
                                <vue-editor
                                  id="editor1D33"
                                  v-model="row.mask3_3"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color3_3"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview3_3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_3 = '';
                                      row.image3_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_3 ? row.preview3_3 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_3Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor2D331"
                                  v-model="row.body3_3_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor3D332"
                                  v-model="row.body3_3_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview3_4"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_4 = '';
                                      row.image3_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_4 ? row.preview3_4 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_4Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line3_4"
                                />
                                <vue-editor
                                  id="editor1D333"
                                  v-model="row.mask3_4"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color3_4"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor2D3310"
                                  v-model="row.body3_4_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor3D33222"
                                  v-model="row.body3_4_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line3_5"
                                />
                                <vue-editor
                                  id="editor1D34"
                                  v-model="row.mask3_5"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color3_5"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview3_5"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_5 = '';
                                      row.image3_5 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_5 ? row.preview3_5 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_5Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor2D341"
                                  v-model="row.body3_5_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor3D342"
                                  v-model="row.body3_5_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview3_6"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_6 = '';
                                      row.image3_6 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_6 ? row.preview3_6 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_6Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line3_6"
                                />
                                <vue-editor
                                  id="editor1D325"
                                  v-model="row.mask3_6"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color3_6"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor2D33251"
                                  v-model="row.body3_6_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor3D33252"
                                  v-model="row.body3_6_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line3_7"
                                />
                                <vue-editor
                                  id="editor1D336"
                                  v-model="row.mask3_7"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color3_7"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview3_7"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_7 = '';
                                      row.image3_7 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_7 ? row.preview3_7 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_7Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor2D3361"
                                  v-model="row.body3_7_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor3D3362"
                                  v-model="row.body3_7_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview3_8"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_8 = '';
                                      row.image3_8 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_8 ? row.preview3_8 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_8Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line3_8"
                                />
                                <vue-editor
                                  id="editor1D373"
                                  v-model="row.mask3_8"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color3_8"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor2D3710"
                                  v-model="row.body3_8_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor3D3722"
                                  v-model="row.body3_8_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line3_9"
                                />
                                <vue-editor
                                  id="editor1D38"
                                  v-model="row.mask3_9"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color3_9"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview3_9"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_9 = '';
                                      row.image3_9 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_9 ? row.preview3_9 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_9Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor2D381"
                                  v-model="row.body3_9_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor3D382"
                                  v-model="row.body3_9_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 3 -->

                  <!-- Card Desgin 4 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent4" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content4')"
                            aria-expanded="false"
                            aria-controls="collapseContent4"
                            data-toggle="collapse"
                            data-target="#collapseContent4"
                          >
                            Group 4
                            <span
                              id="iconToggleContent4"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent4"
                          class="collapse"
                          aria-labelledby="TabContent4"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <vue-editor
                                id="editor1D24"
                                v-model="row.body4_0"
                                :api-key="editor.api_key"
                              />
                            </div>

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D41"
                                  v-model="row.body4_1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12"></div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D42"
                                  v-model="row.body4_2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D43"
                                  v-model="row.body4_3"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12"></div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D44"
                                  v-model="row.body4_4"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D45"
                                  v-model="row.body4_5"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12"></div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D46"
                                  v-model="row.body4_6"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D47"
                                  v-model="row.body4_7"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12"></div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D48"
                                  v-model="row.body4_8"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D49"
                                  v-model="row.body4_9"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12"></div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editor6D410"
                                  v-model="row.body4_10"
                                  :api-key="editor.api_key"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 4 -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Create Accreditation</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "create-comp",

  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      editor: VueEditor,
      content: "<h1>Type your content here!</h1>",
      auth: {
        access_token: "",
      },
      row: {
        // image
        preview: "",
        image: "",

        // mask
        bgTitle: "",
        bgColor: "",

        // pg Name
        title: "",
        slug: "",
        sort: 0,

        status: 1,
        has_faq: 0,
        has_application: 0,
        has_download: 0,
        has_payment: 0,

        faq_link: "",
        payment_name: "",
        payment_link: "",

        download_name: "",
        download_image_preview: "",
        download_image: "",
        download_preview: "",
        download_file: "",

        application_name: "",
        application_path: "",

        // F@King design
        // D1
        body1_1: "",

        // D2
        body2_1: "",
        body2_2: "",
        body2_3: "",
        body2_4: "",
        body2_5: "",

        body2_6: "",
        preview2_6: "",
        image2_6: "",

        body2_7: "",
        preview2_7: "",
        image2_7: "",

        body2_8: "",
        preview2_8: "",
        image2_8: "",

        body2_9: "",
        preview2_9: "",
        image2_9: "",

        body2_10: "",
        preview2_10: "",
        image2_10: "",

        body2_11: "",
        preview2_11: "",
        image2_11: "",

        body2_12: "",
        preview2_12: "",
        image2_12: "",

        body2_13: "",
        preview2_13: "",
        image2_13: "",

        // d3
        body3_1: "",

        preview3_2: "",
        image3_2: "",
        line3_2: "",
        mask3_2: "",
        color3_2: "",
        body3_2_1: "",
        body3_2_2: "",

        preview3_3: "",
        image3_3: "",
        line3_3: "",
        mask3_3: "",
        color3_3: "",
        body3_3_1: "",
        body3_3_2: "",

        preview3_4: "",
        image3_4: "",
        line3_4: "",
        mask3_4: "",
        color3_4: "",
        body3_4_1: "",
        body3_4_2: "",

        preview3_5: "",
        image3_5: "",
        line3_5: "",
        mask3_5: "",
        color3_5: "",
        body3_5_1: "",
        body3_5_2: "",

        preview3_6: "",
        image3_6: "",
        line3_6: "",
        mask3_6: "",
        color3_6: "",
        body3_6_1: "",
        body3_6_2: "",

        preview3_7: "",
        image3_7: "",
        line3_7: "",
        mask3_7: "",
        color3_7: "",
        body3_7_1: "",
        body3_7_2: "",

        preview3_8: "",
        image3_8: "",
        line3_8: "",
        mask3_8: "",
        color3_8: "",
        body3_8_1: "",
        body3_8_2: "",

        preview3_9: "",
        image3_9: "",
        line3_9: "",
        mask3_9: "",
        color3_9: "",
        body3_9_1: "",
        body3_9_2: "",

        // d4
        body4_0: "",
        body4_1: "",
        body4_2: "",
        body4_3: "",
        body4_4: "",
        body4_5: "",
        body4_6: "",
        body4_7: "",
        body4_8: "",
        body4_9: "",
        body4_10: "",
      },
      // editor: {
      //   api_key: "xahz1dg338xnac8il0tkxph26xcaxqaewi3bd9cw9t4e6j7b",
      //   menubar: "file edit view insert format  table tc ",
      //   plugins: [
      //     "advlist autolink lists link image charmap print preview anchor",
      //     "searchreplace visualblocks code fullscreen",
      //     "insertdatetime media table paste code help wordcount",
      //   ],
      //   toolbar:
      //     "undo redo | formatselect | bold italic backcolor | \
      //                         alignleft aligncenter alignright alignjustify | \
      //                         bullist numlist outdent indent | removeformat | help",
      // },

      croppieImage: "",
      cropped: false,

      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
  },
  methods: {
    // Add New
    addNew() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/accreditations",
        method: "POST",
        data: {
          // image
          image: this.row.image,

          // mask
          bgTitle: this.row.bgTitle,
          bgColor: this.row.bgColor,

          // pgName
          title: this.row.title,
          slug: this.row.slug,
          sort: this.row.sort,

          status: this.row.status,
          has_faq: this.row.has_faq,
          has_application: this.row.has_application,
          has_download: this.row.has_download,
          has_payment: this.row.has_payment,

          faq_link: this.row.faq_link,
          payment_name: this.row.payment_name,
          payment_link: this.row.payment_link,

          download_name: this.row.download_name,
          download_image: this.row.download_image,
          download_file: this.row.download_file,

          application_name: this.row.application_name,
          application_path: this.row.application_path,

          // d1
          body1_1: this.row.body1_1,

          // d2
          body2_1: this.row.body2_1,
          body2_2: this.row.body2_2,
          body2_3: this.row.body2_3,
          body2_4: this.row.body2_4,
          body2_5: this.row.body2_5,

          body2_6: this.row.body2_6,
          image2_6: this.row.image2_6,

          body2_7: this.row.body2_7,
          image2_7: this.row.image2_7,

          body2_8: this.row.body2_8,
          image2_8: this.row.image2_8,

          body2_9: this.row.body2_9,
          image2_9: this.row.image2_9,

          body2_10: this.row.body2_10,
          image2_10: this.row.image2_10,

          body2_11: this.row.body2_11,
          image2_11: this.row.image2_11,

          body2_12: this.row.body2_12,
          image2_12: this.row.image2_12,

          body2_13: this.row.body2_13,
          image2_13: this.row.image2_13,

          // d3
          body3_1: this.row.body3_1,

          image3_2: this.row.image3_2,
          line3_2: this.row.line3_2,
          mask3_2: this.row.mask3_2,
          color3_2: this.row.color3_2,
          body3_2_1: this.row.body3_2_1,
          body3_2_2: this.row.body3_2_2,

          image3_3: this.row.image3_3,
          line3_3: this.row.line3_3,
          mask3_3: this.row.mask3_3,
          color3_3: this.row.color3_3,
          body3_3_1: this.row.body3_3_1,
          body3_3_2: this.row.body3_3_2,

          image3_4: this.row.image3_4,
          line3_4: this.row.line3_4,
          mask3_4: this.row.mask3_4,
          color3_4: this.row.color3_4,
          body3_4_1: this.row.body3_4_1,
          body3_4_2: this.row.body3_4_2,

          image3_5: this.row.image3_5,
          line3_5: this.row.line3_5,
          mask3_5: this.row.mask3_5,
          color3_5: this.row.color3_5,
          body3_5_1: this.row.body3_5_1,
          body3_5_2: this.row.body3_5_2,

          image3_6: this.row.image3_6,
          line3_6: this.row.line3_6,
          mask3_6: this.row.mask3_6,
          color3_6: this.row.color3_6,
          body3_6_1: this.row.body3_6_1,
          body3_6_2: this.row.body3_6_2,

          image3_7: this.row.image3_7,
          line3_7: this.row.line3_7,
          mask3_7: this.row.mask3_7,
          color3_7: this.row.color3_7,
          body3_7_1: this.row.body3_7_1,
          body3_7_2: this.row.body3_7_2,

          image3_8: this.row.image3_8,
          line3_8: this.row.line3_8,
          mask3_8: this.row.mask3_8,
          color3_8: this.row.color3_8,
          body3_8_1: this.row.body3_8_1,
          body3_8_2: this.row.body3_8_2,

          image3_9: this.row.image3_9,
          line3_9: this.row.line3_9,
          mask3_9: this.row.mask3_9,
          color3_9: this.row.color3_9,
          body3_9_1: this.row.body3_9_1,
          body3_9_2: this.row.body3_9_2,

          // d4
          body4_0: this.row.body4_0,
          body4_1: this.row.body4_1,
          body4_2: this.row.body4_2,
          body4_3: this.row.body4_3,
          body4_4: this.row.body4_4,
          body4_5: this.row.body4_5,
          body4_6: this.row.body4_6,
          body4_7: this.row.body4_7,
          body4_8: this.row.body4_8,
          body4_9: this.row.body4_9,
          body4_10: this.row.body4_10,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Added Successfully.",
          });
          this.$router.push({ name: "accreditations" });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Title
    onTitleChange() {
      this.onSlugChange(this.row.title);
    },

    // on Paste
    onSlugPaste() {
      let str = this.row.slug;
      this.onSlugChange(str);
    },
    onSlugChange(str) {
      if (!str) {
        let str = this.row.slug;
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      } else {
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      }
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    // d1
    onImage2_6Change(e) {
      const file = e.target.files[0];
      this.row.preview2_6 = URL.createObjectURL(file);
      this.createBase64Image2_6(file);
    },
    createBase64Image2_6(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_6 = e.target.result;
      };
    },
    onImage2_7Change(e) {
      const file = e.target.files[0];
      this.row.preview2_7 = URL.createObjectURL(file);
      this.createBase64Image2_7(file);
    },
    createBase64Image2_7(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_7 = e.target.result;
      };
    },
    onImage2_8Change(e) {
      const file = e.target.files[0];
      this.row.preview2_8 = URL.createObjectURL(file);
      this.createBase64Image2_8(file);
    },
    createBase64Image2_8(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_8 = e.target.result;
      };
    },
    onImage2_9Change(e) {
      const file = e.target.files[0];
      this.row.preview2_9 = URL.createObjectURL(file);
      this.createBase64Image2_9(file);
    },
    createBase64Image2_9(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_9 = e.target.result;
      };
    },
    onImage2_10Change(e) {
      const file = e.target.files[0];
      this.row.preview2_10 = URL.createObjectURL(file);
      this.createBase64Image2_10(file);
    },
    createBase64Image2_10(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_10 = e.target.result;
      };
    },
    onImage2_11Change(e) {
      const file = e.target.files[0];
      this.row.preview2_11 = URL.createObjectURL(file);
      this.createBase64Image2_11(file);
    },
    createBase64Image2_11(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_11 = e.target.result;
      };
    },
    onImage2_12Change(e) {
      const file = e.target.files[0];
      this.row.preview2_12 = URL.createObjectURL(file);
      this.createBase64Image2_12(file);
    },
    createBase64Image2_12(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_12 = e.target.result;
      };
    },
    onImage2_13Change(e) {
      const file = e.target.files[0];
      this.row.preview2_13 = URL.createObjectURL(file);
      this.createBase64Image2_13(file);
    },
    createBase64Image2_13(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_13 = e.target.result;
      };
    },

    onImage3_2Change(e) {
      const file = e.target.files[0];
      this.row.preview3_2 = URL.createObjectURL(file);
      this.createBase64Image3_2(file);
    },
    createBase64Image3_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_2 = e.target.result;
      };
    },
    onImage3_3Change(e) {
      const file = e.target.files[0];
      this.row.preview3_3 = URL.createObjectURL(file);
      this.createBase64Image3_3(file);
    },
    createBase64Image3_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_3 = e.target.result;
      };
    },
    onImage3_4Change(e) {
      const file = e.target.files[0];
      this.row.preview3_4 = URL.createObjectURL(file);
      this.createBase64Image3_4(file);
    },
    createBase64Image3_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_4 = e.target.result;
      };
    },
    onImage3_5Change(e) {
      const file = e.target.files[0];
      this.row.preview3_5 = URL.createObjectURL(file);
      this.createBase64Image3_5(file);
    },
    createBase64Image3_5(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_5 = e.target.result;
      };
    },
    onImage3_6Change(e) {
      const file = e.target.files[0];
      this.row.preview3_6 = URL.createObjectURL(file);
      this.createBase64Image3_6(file);
    },
    createBase64Image3_6(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_6 = e.target.result;
      };
    },
    onImage3_7Change(e) {
      const file = e.target.files[0];
      this.row.preview3_7 = URL.createObjectURL(file);
      this.createBase64Image3_7(file);
    },
    createBase64Image3_7(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_7 = e.target.result;
      };
    },
    onImage3_8Change(e) {
      const file = e.target.files[0];
      this.row.preview3_8 = URL.createObjectURL(file);
      this.createBase64Image3_8(file);
    },
    createBase64Image3_8(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_8 = e.target.result;
      };
    },
    onImage3_9Change(e) {
      const file = e.target.files[0];
      this.row.preview3_9 = URL.createObjectURL(file);
      this.createBase64Image3_9(file);
    },
    createBase64Image3_9(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_9 = e.target.result;
      };
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.row.download_preview = URL.createObjectURL(file);
      this.createBase64File(file);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_file = e.target.result;
      };
    },

    onFilePreviewChange(e) {
      const file = e.target.files[0];
      this.row.download_image_preview = URL.createObjectURL(file);
      this.createBase64FilePreview(file);
    },
    createBase64FilePreview(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_image = e.target.result;
      };
    },

    // active status
    onStatus() {
      if (this.row.status) this.row.status = 0;
      else this.row.status = 1;
    },
    onFaq() {
      if (this.row.has_faq) this.row.has_faq = 0;
      else this.row.has_faq = 1;
    },
    onPayment() {
      if (this.row.has_payment) this.row.has_payment = 0;
      else this.row.has_payment = 1;
    },
    onApplication() {
      if (this.row.has_application) this.row.has_application = 0;
      else this.row.has_application = 1;
    },
    onDownload() {
      if (this.row.has_download) this.row.has_download = 0;
      else this.row.has_download = 1;
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "accreditations" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
