/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">
            Certificates

            <router-link
              :to="{ name: 'products-certificates' }"
              class="btn btn-danger btn-sm btn-pill ui-mt-10 ui-mb-2"
            >
              <span>Go To Programs Section</span>
            </router-link>

            <div class="pull-rights ui-mt-15 pull-right">
              <div class="dropdown">
                <span class="">
                  <div class="form-group" v-if="!pgLoading">
                    <div class="custom-control custom-switch mb-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                        :checked="row.status"
                        @click="onStatus"
                      />
                      <label
                        class="custom-control-label"
                        for="customSwitch1"
                        v-html="
                          row.status ? 'Show Certificates' : 'Hide Certificates'
                        "
                      >
                      </label>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Certificates
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="spinner-grow mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Loading -->

          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Overview -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview')"
                            aria-expanded="false"
                            aria-controls="collapseOverview"
                            data-toggle="collapse"
                            data-target="#collapseOverview"
                          >
                            Overview
                            <span
                              id="iconToggleOverview"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview"
                          class="collapse"
                          aria-labelledby="TabOverview"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>

                            <div class="row col-12" style="padding: 0">
                              <!-- Title -->
                              <div class="form-group col-10">
                                <label for="input1">background SubTitle</label>
                                <vue-editor
                                  id="editor001"
                                  v-model="row.bgSubTitle1"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>

                              <!-- Slug -->
                              <div class="form-group col-2">
                                <label for="input2">line Color</label>
                                <input
                                  class="form-control"
                                  id="input2"
                                  type="color"
                                  style="height: 200px"
                                  v-model="row.lineColor1"
                                />
                              </div>
                              <!-- End Slug -->
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group col-10">
                                <label for="input1">background Title</label>
                                <vue-editor
                                  id="editor0001"
                                  v-model="row.bgTitle1"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Slug -->
                              <div class="form-group col-2">
                                <label for="input2">background Color</label>
                                <input
                                  class="form-control"
                                  id="input2"
                                  type="color"
                                  style="height: 200px"
                                  v-model="row.bgColor1"
                                />
                              </div>
                              <!-- End Slug -->

                              <!-- Body -->
                              <div class="form-group col-12">
                                <label for="input4">Body</label>
                                <vue-editor
                                  id="input4"
                                  v-model="row.body1"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <!-- End Body -->

                              <h4><br />Status & Visibility</h4>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <div
                                    class="custom-control custom-switch mb-2"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="customSwitch2"
                                      :checked="row.has_download"
                                      @click="onDownload"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customSwitch2"
                                      v-html="
                                        row.has_download
                                          ? 'Show Download File'
                                          : 'Hide Download File'
                                      "
                                    >
                                    </label>
                                  </div>
                                </div>

                                <div class="col-12 row" v-if="row.has_download">
                                  <div class="form-group col-8">
                                    <label>Download Title</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="row.download_name"
                                    />
                                  </div>
                                  <div class="form-group col-4">
                                    <label
                                      >Download File
                                      <button
                                        v-if="row.download_preview"
                                        type="button"
                                        class="btn btn-danger btn-sm btn-pill"
                                        style="
                                          margin-top: -10px;
                                          margin-left: 10px;
                                        "
                                        @click="
                                          row.download_preview = '';
                                          row.download_file = '';
                                        "
                                      >
                                        Remove File
                                      </button>
                                    </label>
                                    <a
                                      v-if="row.download_preview"
                                      :href="row.download_preview"
                                      target="_blank"
                                      class="pull-right"
                                      >Preview
                                    </a>
                                    <input
                                      type="file"
                                      class="form-control"
                                      @change="onFileChange"
                                    />
                                  </div>
                                  <div class="form-group col-md-6 col-sm-12">
                                    <label
                                      >Download Image 180 * 220
                                      <button
                                        v-if="row.download_image_preview"
                                        type="button"
                                        class="btn btn-danger btn-sm btn-pill"
                                        style="
                                          margin-top: -10px;
                                          margin-left: 10px;
                                        "
                                        @click="
                                          row.download_image_preview = '';
                                          row.download_image = '';
                                        "
                                      >
                                        Remove Image
                                      </button>
                                    </label>
                                    <p>
                                      <img
                                        v-if="row.download_image_preview"
                                        :src="row.download_image_preview"
                                        style="max-width: 50%"
                                      />
                                    </p>
                                    <input
                                      type="file"
                                      class="form-control"
                                      @change="onFilePreviewChange"
                                      accept="image/*"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Overview -->

                  <!-- Card Certificate Type -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview2')"
                            aria-expanded="false"
                            aria-controls="collapseOverview2"
                            data-toggle="collapse"
                            data-target="#collapseOverview2"
                          >
                            Certification Type
                            <span
                              id="iconToggleOverview2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview2"
                          class="collapse"
                          aria-labelledby="TabOverview2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 660 * 390
                                <button
                                  v-if="row.preview2"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview2 = '';
                                    row.image2 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview2"
                                  :src="row.preview2"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImage2Change"
                              />
                            </div>

                            <div class="row col-12" style="padding: 0">
                              <!-- Title -->
                              <div class="form-group col-12">
                                <label for="input000x1"
                                  >background SubTitle</label
                                >
                                <!-- <input class="form-control" 
                                                        id="input1" 
                                                        type="text" 
                                                        v-model="row.bgSubTitle2"> -->
                                <vue-editor
                                  id="editor000x1"
                                  v-model="row.bgSubTitle2"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Title -->
                              <div class="form-group col-10">
                                <label for="input000x2">background Title</label>
                                <!-- <textarea class="form-control" 
                                                        id="input1" 
                                                        rows="3" 
                                                        v-model="row.bgTitle2"></textarea> -->
                                <vue-editor
                                  id="editor000x2"
                                  v-model="row.bgTitle2"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 200,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Slug -->
                              <div class="form-group col-2">
                                <label for="input2">background Color</label>
                                <input
                                  class="form-control"
                                  id="input2"
                                  type="color"
                                  style="height: 200px"
                                  v-model="row.bgColor2"
                                />
                              </div>
                              <!-- End Slug -->

                              <!-- Body -->
                              <div class="form-group col-12">
                                <label for="input4_2">Body</label>
                                <vue-editor
                                  id="input4_2"
                                  v-model="row.body2"
                                  :api-key="editor.api_key"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-12">
                                <label for="input4_3">Hint</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.hint2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Certificate Type -->

                  <!-- Card Certificates -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Certificates
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12" style="padding: 0">
                              <div class="col-4">
                                <h4>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.cat1_name"
                                  />
                                </h4>
                                <hr />
                                <div
                                  v-for="(i, idx1) in row.cat1_key"
                                  :key="idx1"
                                  :id="'frm1_' + i"
                                >
                                  <div class="form-group">
                                    <br />
                                    <label style="width: 100%"
                                      >Title
                                      <div class="pull-right">
                                        <button
                                          type="button"
                                          @click="removeCat1(i)"
                                          class="btn btn-danger btn-pill btn-sm"
                                        >
                                          x
                                        </button>
                                      </div>
                                    </label>
                                    <input
                                      type="text"
                                      v-model="row.cat1_title[i]"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label>Image 403 * 285</label>
                                    <p>
                                      <img
                                        v-if="row.cat1_preview[i]"
                                        :src="row.cat1_preview[i]"
                                        style="max-width: 100%"
                                      />
                                    </p>
                                    <input
                                      type="file"
                                      v-on:change="onCat1ImageChange($event, i)"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label>PDF</label>
                                    <a
                                      v-if="row.cat1_pdf_preview[i]"
                                      :href="row.cat1_pdf_preview[i]"
                                      target="_blank"
                                      class="pull-right"
                                      >Preview
                                    </a>
                                    <input
                                      type="file"
                                      v-on:change="onPDF1ImageChange($event, i)"
                                      class="form-control"
                                    />
                                  </div>
                                  <br />
                                  <hr />
                                  <br />
                                </div>
                                <br />
                                <button
                                  type="button"
                                  @click="addMore1"
                                  class="btn btn-dark pull-right btn-pill btn-sm"
                                >
                                  +
                                </button>
                                <br />
                              </div>

                              <div class="col-4">
                                <h4>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.cat2_name"
                                  />
                                </h4>

                                <hr />
                                <div
                                  v-for="(i, idx2) in row.cat2_key"
                                  :key="idx2"
                                  :id="'frm2_' + i"
                                >
                                  <div class="form-group">
                                    <br />
                                    <label style="width: 100%"
                                      >Title
                                      <div class="pull-right">
                                        <button
                                          type="button"
                                          @click="removeCat2(i)"
                                          class="btn btn-danger btn-pill btn-sm"
                                        >
                                          x
                                        </button>
                                      </div>
                                    </label>
                                    <input
                                      type="text"
                                      v-model="row.cat2_title[i]"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label>Image 220 * 310</label>
                                    <p>
                                      <img
                                        v-if="row.cat2_preview[i]"
                                        :src="row.cat2_preview[i]"
                                        style="max-width: 100%"
                                      />
                                    </p>
                                    <input
                                      type="file"
                                      v-on:change="onCat2ImageChange($event, i)"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label>PDF</label>
                                    <a
                                      v-if="row.cat2_pdf_preview[i]"
                                      :href="row.cat2_pdf_preview[i]"
                                      target="_blank"
                                      class="pull-right"
                                      >Preview
                                    </a>
                                    <input
                                      type="file"
                                      v-on:change="onPDF2ImageChange($event, i)"
                                      class="form-control"
                                    />
                                  </div>
                                  <br />
                                  <hr />
                                  <br />
                                </div>
                                <br />
                                <button
                                  type="button"
                                  @click="addMore2"
                                  class="btn btn-dark pull-right btn-pill btn-sm"
                                >
                                  +
                                </button>
                                <br />
                              </div>

                              <div class="col-4">
                                <h4>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.cat3_name"
                                  />
                                </h4>
                                <hr />
                                <div
                                  v-for="(i, idx3) in row.cat3_key"
                                  :key="idx3"
                                  :id="'frm3_' + i"
                                >
                                  <div class="form-group">
                                    <br />
                                    <label style="width: 100%"
                                      >Title
                                      <div class="pull-right">
                                        <button
                                          type="button"
                                          @click="removeCat3(i)"
                                          class="btn btn-danger btn-pill btn-sm"
                                        >
                                          x
                                        </button>
                                      </div>
                                    </label>
                                    <input
                                      type="text"
                                      v-model="row.cat3_title[i]"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label>Image</label>
                                    <p>
                                      <img
                                        v-if="row.cat3_preview[i]"
                                        :src="row.cat3_preview[i]"
                                        style="max-width: 100%"
                                      />
                                    </p>
                                    <input
                                      type="file"
                                      v-on:change="onCat3ImageChange($event, i)"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label>PDF</label>
                                    <a
                                      v-if="row.cat3_pdf_preview[i]"
                                      :href="row.cat3_pdf_preview[i]"
                                      target="_blank"
                                      class="pull-right"
                                      >Preview
                                    </a>
                                    <input
                                      type="file"
                                      v-on:change="onPDF3ImageChange($event, i)"
                                      class="form-control"
                                    />
                                  </div>
                                  <br />
                                  <hr />
                                  <br />
                                </div>
                                <br />
                                <button
                                  type="button"
                                  @click="addMore3"
                                  class="btn btn-dark pull-right btn-pill btn-sm"
                                >
                                  +
                                </button>
                                <br />
                              </div>
                            </div>

                            <!-- Body -->
                            <div class="form-group">
                              <p><br /></p>
                              <div class="row col-12">
                                <div class="col-4">
                                  <label>Training Duration 1</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.duration1"
                                  />
                                </div>
                                <div class="col-4">
                                  <label>Training Duration 2</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.duration2"
                                  />
                                </div>
                                <div class="col-4">
                                  <label>Training Duration 3</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.duration3"
                                  />
                                </div>
                              </div>
                            </div>
                            <!-- End Body -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Certificates -->

                  <!-- Card Programs -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content3')"
                            aria-expanded="false"
                            aria-controls="collapseContent3"
                            data-toggle="collapse"
                            data-target="#collapseContent3"
                          >
                            Certification of Training Program
                            <span
                              id="iconToggleContent3"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent3"
                          class="collapse"
                          aria-labelledby="TabContent3"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Image
                                <button
                                  v-if="row.preview3"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview3 = '';
                                    row.image3 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview3"
                                  :src="row.preview3"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onDImageChange"
                              />
                            </div>
                            <div class="form-group">
                              <label>Title</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="row.dTitle"
                              />
                            </div>

                            <div class="form-group">
                              <p><br /><br /></p>
                              <router-link
                                :to="{ name: 'products-certificates' }"
                                class="btn btn-danger btn-sm btn-pill ui-mt-10 ui-mb-2"
                              >
                                <span>Go To Programs Section</span>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Programs -->

                  <!-- Card Online Training -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content2')"
                            aria-expanded="false"
                            aria-controls="collapseContent2"
                            data-toggle="collapse"
                            data-target="#collapseContent2"
                          >
                            Certificate of Authentications
                            <span
                              id="iconToggleContent2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent2"
                          class="collapse"
                          aria-labelledby="TabContent2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12">
                              <div class="form-group col-md-6 col-sm-12">
                                <label>
                                  <button
                                    v-if="row.preview5_1"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5_1 = '';
                                      row.image5_1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <p>
                                  <img
                                    :src="row.preview5_1"
                                    style="max-width: 100%; height: 225px"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage5_1Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input4c1"
                                  v-model="row.cBody1"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 350,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input4c2"
                                  v-model="row.cBody2"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 350,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label>
                                  <button
                                    v-if="row.preview5_2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5_2 = '';
                                      row.image5_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <p>
                                  <img
                                    :src="row.preview5_2"
                                    style="max-width: 100%; height: 225px"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage5_2Change"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label>
                                  <button
                                    v-if="row.preview5_3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5_3 = '';
                                      row.image5_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    :src="row.preview5_3"
                                    style="width: 100%; height: 260px"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage5_3Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input4c3"
                                  v-model="row.cBody3"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 350,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input4c4"
                                  v-model="row.cBody4"
                                  :api-key="editor.api_key"
                                  :init="{
                                    height: 350,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label>
                                  <button
                                    v-if="row.preview5_4"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5_4 = '';
                                      row.image5_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    :src="row.preview5_4"
                                    style="max-width: 100%; height: 260px"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage5_4Change"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Online Training -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Certificate</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "edit-comp",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      editor: VueEditor,
      content: "<h1>Type your content here!</h1>",
      auth: {
        access_token: "",
      },
      row: {
        status: 0,
        // div 1
        preview: "",
        image: "",
        bgTitle1: "",
        bgSubTitle1: "",
        lineColor1: "",
        bgColor1: "",
        body1: "",

        has_download: 0,
        download_name: "",
        download_image_preview: "",
        download_image: "",
        download_preview: "",
        download_file: "",

        // div2
        preview2: "",
        image2: "",
        bgTitle2: "",
        bgSubTitle2: "",
        bgColor2: "",
        body2: "",
        hint2: "",

        // Cat 1
        cat1: [],
        cat1_key: 0,
        cat1_title: [],
        cat1_preview: [],
        cat1_image: [],
        cat1_pdf: [],
        cat1_pdf_preview: [],

        // Cat 2
        cat2: [],
        cat2_key: 0,
        cat2_title: [],
        cat2_preview: [],
        cat2_image: [],
        cat2_pdf: [],
        cat2_pdf_preview: [],

        // Cat 3
        cat3: [],
        cat3_key: 0,
        cat3_title: [],
        cat3_preview: [],
        cat3_image: [],
        cat3_pdf: [],
        cat3_pdf_preview: [],

        duration1: "",
        duration2: "",
        duration3: "",

        //
        dTitle: "",
        preview3: "",
        image3: "",

        //
        cPreview: "",
        cImage: "",
        cTitle: "",

        cBody1: "",
        image5_1: "",
        preview5_1: "",

        cBody2: "",
        image5_2: "",
        preview5_2: "",

        cBody3: "",
        image5_3: "",
        preview5_3: "",

        cBody4: "",
        image5_4: "",
        preview5_4: "",

        cat1_name: "",
        cat2_name: "",
        cat3_name: "",
      },

      croppieImage: "",
      cropped: false,

      pgLoading: false,
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        //'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/certificates/1",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          // div 1
          this.row.preview = res.data.row.image;
          this.row.image = res.data.row.image;
          this.row.bgTitle1 = res.data.row.bgTitle1;
          this.row.bgSubTitle1 = res.data.row.bgSubTitle1;
          this.row.lineColor1 = res.data.row.lineColor1;
          this.row.bgColor1 = res.data.row.bgColor1;

          this.row.body1 = res.data.row.body1;

          this.row.cat1_name = res.data.row.cat1_name.body1;
          this.row.cat2_name = res.data.row.cat2_name.body2;
          this.row.cat3_name = res.data.row.cat3_name.body3;

          this.row.has_download = res.data.row.has_download;
          this.row.download_name = res.data.row.download_name;
          this.row.download_file = res.data.row.download_file;
          this.row.download_preview = res.data.row.download_file;
          this.row.download_image = res.data.row.download_image;
          this.row.download_image_preview = res.data.row.download_image;

          // div2
          this.row.preview2 = res.data.row.image2;
          this.row.image2 = res.data.row.image2;
          this.row.bgTitle2 = res.data.row.bgTitle2;
          this.row.bgSubTitle2 = res.data.row.bgSubTitle2;
          this.row.bgColor2 = res.data.row.bgColor2;
          this.row.body2 = res.data.row.body2;
          this.row.hint2 = res.data.row.hint2;

          // Cat 1
          this.row.cat1_key = res.data.row.cat1.length;
          if (res.data.row.cat1) {
            for (let i = 1; i <= this.row.cat1_key; i++) {
              let x = i - 1;
              this.row.cat1_title[i] = res.data.row.cat1[x].title;
              this.row.cat1_preview[i] = res.data.row.cat1[x].image;
              this.row.cat1_image[i] = res.data.row.cat1[x].image;
              this.row.cat1_pdf[i] = res.data.row.cat1[x].pdf;
              this.row.cat1_pdf_preview[i] = res.data.row.cat1[x].pdf;
            }
          }

          // Cat 2
          this.row.cat2_key = res.data.row.cat2.length;
          if (res.data.row.cat2) {
            for (let i = 1; i <= this.row.cat2_key; i++) {
              let x = i - 1;
              this.row.cat2_title[i] = res.data.row.cat2[x].title;
              this.row.cat2_preview[i] = res.data.row.cat2[x].image;
              this.row.cat2_image[i] = res.data.row.cat2[x].image;
              this.row.cat2_pdf[i] = res.data.row.cat2[x].pdf;
              this.row.cat2_pdf_preview[i] = res.data.row.cat2[x].pdf;
            }
          }

          // Cat 3
          this.row.cat3_key = res.data.row.cat3.length;
          if (res.data.row.cat3) {
            for (let i = 1; i <= this.row.cat3_key; i++) {
              let x = i - 1;
              this.row.cat3_title[i] = res.data.row.cat3[x].title;
              this.row.cat3_preview[i] = res.data.row.cat3[x].image;
              this.row.cat3_image[i] = res.data.row.cat3[x].image;
              this.row.cat3_pdf[i] = res.data.row.cat3[x].pdf;
              this.row.cat3_pdf_preview[i] = res.data.row.cat3[x].pdf;
            }
          }

          this.row.duration1 = res.data.row.duration1;
          this.row.duration2 = res.data.row.duration2;
          this.row.duration3 = res.data.row.duration3;
          //
          this.row.dTitle = res.data.row.dTitle;
          this.row.preview3 = res.data.row.image3;
          this.row.image3 = res.data.row.image3;

          //
          this.row.cTitle = res.data.row.cTitle;

          this.row.cBody1 = res.data.row.cBody1;
          this.row.preview5_1 = res.data.row.image5_1;
          this.row.image5_1 = res.data.row.image5_1;

          this.row.cBody2 = res.data.row.cBody2;
          this.row.image5_2 = res.data.row.image5_2;
          this.row.preview5_2 = res.data.row.image5_2;

          this.row.cBody3 = res.data.row.cBody3;
          this.row.image5_3 = res.data.row.image5_3;
          this.row.preview5_3 = res.data.row.image5_3;

          this.row.cBody4 = res.data.row.cBody4;
          this.row.image5_4 = res.data.row.image5_4;
          this.row.preview5_4 = res.data.row.image5_3;

          this.row.status = res.data.active.status;
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        //'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };

      for (let i = 1; i < this.row.cat1_title.length; i++) {
        this.row.cat1[i] = {
          cat_title: this.row.cat1_title[i],
          cat_image: this.row.cat1_image[i],
          cat_pdf: this.row.cat1_pdf[i],
        };
      }

      for (let i = 1; i < this.row.cat2_title.length; i++) {
        this.row.cat2[i] = {
          cat_title: this.row.cat2_title[i],
          cat_image: this.row.cat2_image[i],
          cat_pdf: this.row.cat2_pdf[i],
        };
      }
      for (let i = 1; i < this.row.cat3_title.length; i++) {
        this.row.cat3[i] = {
          cat_title: this.row.cat3_title[i],
          cat_image: this.row.cat3_image[i],
          cat_pdf: this.row.cat3_pdf[i],
        };
      }
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/certificates/1",
        method: "PUT",
        data: {
          cat1_name: this.row.cat1_name,
          cat2_name: this.row.cat2_name,
          cat3_name: this.row.cat3_name,

          image: this.row.image,
          bgTitle1: this.row.bgTitle1,
          bgSubTitle1: this.row.bgSubTitle1,
          lineColor1: this.row.lineColor1,
          bgColor1: this.row.bgColor1,
          body1: this.row.body1,
          has_download: this.row.has_download,
          download_name: this.row.download_name,
          download_image: this.row.download_image,
          download_file: this.row.download_file,

          // div2
          image2: this.row.image2,
          bgTitle2: this.row.bgTitle2,
          bgSubTitle2: this.row.bgSubTitle2,
          bgColor2: this.row.bgColor2,
          body2: this.row.body2,
          hint2: this.row.hint2,

          // Cat 1
          cat1: this.row.cat1,
          cat2: this.row.cat2,
          cat3: this.row.cat3,

          duration1: this.row.duration1,
          duration2: this.row.duration2,
          duration3: this.row.duration3,

          //
          dTitle: this.row.dTitle,
          image3: this.row.image3,

          //
          cTitle: this.row.cTitle,

          cBody1: this.row.cBody1,
          image5_1: this.row.image5_1,

          cBody2: this.row.cBody2,
          image5_2: this.row.image5_2,

          cBody3: this.row.cBody3,
          image5_3: this.row.image5_3,

          cBody4: this.row.cBody4,
          image5_4: this.row.image5_4,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Updated Successfully.",
          });
          //this.$router.push({ name: 'products'})
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Upload Featured image
    onDImageChange(e) {
      const file = e.target.files[0];
      this.row.preview3 = URL.createObjectURL(file);
      this.createDBase64Image(file);
    },
    createDBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3 = e.target.result;
      };
    },
    onImage2Change(e) {
      const file = e.target.files[0];
      this.row.preview2 = URL.createObjectURL(file);
      this.createCBase64Image2(file);
    },
    createCBase64Image2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2 = e.target.result;
      };
    },

    // Upload Featured image

    // active status
    onStatus() {
      if (this.row.status) {
        this.row.status = 0;

        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/settings/inactive/22",
          method: "POST",
          data: {},
        };
        this.axios(options)
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      } else {
        this.row.status = 1;

        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/settings/active/22",
          method: "POST",
          data: {},
        };
        this.axios(options)
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      }
    },

    onDownload() {
      if (this.row.has_download) this.row.has_download = 0;
      else this.row.has_download = 1;
    },

    addMore1() {
      this.row.cat1_key++;
    },
    removeCat1(i) {
      document.querySelector("#frm1_" + i).remove();
      this.row.cat1_title[i] = "";
      this.row.cat1_preview[i] = "";
      this.row.cat1_image[i] = "";
      this.row.cat1_pdf[i] = "";
      this.row.cat1_pdf_preview[i] = "";
    },
    onCat1ImageChange(e, i) {
      const file = e.target.files[0];
      this.row.cat1_preview[i] = URL.createObjectURL(file);
      this.createBase64Cat1(file, i);
    },
    createBase64Cat1(fileObject, i) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.cat1_image[i] = e.target.result;
      };
    },
    onPDF1ImageChange(e, i) {
      const file = e.target.files[0];
      this.createPDF64Cat1(file, i);
    },
    createPDF64Cat1(fileObject, i) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.cat1_pdf[i] = e.target.result;
      };
    },

    addMore2() {
      this.row.cat2_key++;
    },
    removeCat2(i) {
      document.querySelector("#frm2_" + i).remove();
      this.row.cat2_title[i] = "";
      this.row.cat2_preview[i] = "";
      this.row.cat2_image[i] = "";
      this.row.cat2_pdf[i] = "";
      this.row.cat2_pdf_preview[i] = "";
    },
    onCat2ImageChange(e, i) {
      const file = e.target.files[0];
      this.row.cat2_preview[i] = URL.createObjectURL(file);
      this.createBase64Cat2(file, i);
    },
    createBase64Cat2(fileObject, i) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.cat2_image[i] = e.target.result;
      };
    },
    onPDF2ImageChange(e, i) {
      const file = e.target.files[0];
      this.createPDF64Cat2(file, i);
    },
    createPDF64Cat2(fileObject, i) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.cat2_pdf[i] = e.target.result;
      };
    },

    addMore3() {
      this.row.cat3_key++;
    },
    removeCat3(i) {
      document.querySelector("#frm3_" + i).remove();
      this.row.cat3_title[i] = "";
      this.row.cat3_preview[i] = "";
      this.row.cat3_image[i] = "";
      this.row.cat3_pdf[i] = "";
      this.row.cat3_pdf_preview[i] = "";
    },
    onCat3ImageChange(e, i) {
      const file = e.target.files[0];
      this.row.cat3_preview[i] = URL.createObjectURL(file);
      this.createBase64Cat3(file, i);
    },
    createBase64Cat3(fileObject, i) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.cat3_image[i] = e.target.result;
      };
    },
    onPDF3ImageChange(e, i) {
      const file = e.target.files[0];
      this.createPDF64Cat3(file, i);
    },
    createPDF64Cat3(fileObject, i) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.cat3_pdf[i] = e.target.result;
      };
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64mage(file);
    },
    createBase64mage(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.row.download_preview = URL.createObjectURL(file);
      this.createBase64File(file);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_file = e.target.result;
      };
    },

    onFilePreviewChange(e) {
      const file = e.target.files[0];
      this.row.download_image_preview = URL.createObjectURL(file);
      this.createBase64FilePreview(file);
    },
    createBase64FilePreview(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_image = e.target.result;
      };
    },

    onImage5_1Change(e) {
      const file = e.target.files[0];
      this.row.preview5_1 = URL.createObjectURL(file);
      this.createBase64File5_1(file);
    },
    createBase64File5_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_1 = e.target.result;
      };
    },
    onImage5_2Change(e) {
      const file = e.target.files[0];
      this.row.preview5_2 = URL.createObjectURL(file);
      this.createBase64File5_2(file);
    },
    createBase64File5_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_2 = e.target.result;
      };
    },
    onImage5_3Change(e) {
      const file = e.target.files[0];
      this.row.preview5_3 = URL.createObjectURL(file);
      this.createBase64File5_3(file);
    },
    createBase64File5_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_3 = e.target.result;
      };
    },
    onImage5_4Change(e) {
      const file = e.target.files[0];
      this.row.preview5_4 = URL.createObjectURL(file);
      this.createBase64File5_4(file);
    },
    createBase64File5_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_4 = e.target.result;
      };
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
