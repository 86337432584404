/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1
            class="h2 mb-2"
            v-html="root == 1 ? 'Sectors' : 'Sub Sectors'"
          ></h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li v-if="root != 1" class="breadcrumb-item" aria-current="page">
                <router-link
                  :to="{
                    name: 'sectors',
                    params: { program_id: program_id, root: 1 },
                  }"
                  >Sectors
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link
                  :to="{
                    name: 'sectors',
                    params: { program_id: program_id, root: root },
                  }"
                  v-html="root == 1 ? 'Sectors' : 'Sub Sectors'"
                >
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Edit</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <p><br /></p>
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p><br /></p>
            </div>
          </div>
          <!-- End Loading -->

          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Image -->
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.base64Image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabMask" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Mask')"
                            aria-expanded="false"
                            aria-controls="collapseMask"
                            data-toggle="collapse"
                            data-target="#collapseMask"
                          >
                            Mask
                            <span
                              id="iconToggleMask"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseMask"
                          class="collapse"
                          aria-labelledby="TabMask"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3 row">
                            <!-- Title -->
                            <div class="form-group col-10">
                              <label for="input01">background Title</label>
                              <!-- <textarea class="form-control" 
                                                id="input01" 
                                                rows="3" 
                                                v-model="row.bgTitle">
                                        </textarea> -->
                              <vue-editor
                                id="input01"
                                v-model="row.bgTitle"
                                
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group col-2">
                              <label for="input2">background Color</label>
                              <input
                                class="form-control"
                                id="input2"
                                type="color"
                                style="height: 85px"
                                v-model="row.bgColor"
                              />
                            </div>
                            <!-- End Slug -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Content
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1">Title</label>
                              <!-- <input class="form-control" 
                                                id="input1" 
                                                type="text" 
                                                v-model="row.title" 
                                                @keyup="onTitleChange"> -->
                              <vue-editor
                                id="input1"
                                v-model="row.title"
                                
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group">
                              <label for="input2">Slug</label>
                              <input
                                class="form-control text-lowercase"
                                id="input2"
                                type="text"
                                v-model="row.slug"
                                @keydown.space.prevent
                                @paste="onSlugPaste"
                                @change="onSlugChange(false)"
                              />
                            </div>
                            <!-- End Slug -->

                            <!-- Sort -->
                            <div class="form-group">
                              <label for="input3">Sort</label>
                              <input
                                class="form-control"
                                id="input3"
                                min="0"
                                type="number"
                                v-model.number="row.sort"
                              />
                            </div>
                            <!-- End Sort -->

                            <!-- Body -->
                            <div class="form-group">
                              <label for="input4">Body</label>
                              <vue-editor
                                id="input4"
                                v-model="row.body"
                                
                                
                              />
                            </div>
                            <!-- End Body -->

                            <h4><br />Status & Visibility</h4>
                            <div class="col-md-12">
                              <!-- Status -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    :checked="row.status"
                                    @click="onStatus"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch1"
                                    v-html="row.status ? 'Active' : 'Inactive'"
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Status -->

                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch2"
                                    :checked="row.view_in_home"
                                    @click="onHome"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch2"
                                    v-html="
                                      row.view_in_home
                                        ? 'Hide In Home'
                                        : 'View In Home'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>

                              <!-- <div class="form-group">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                    class="custom-control-input" 
                                                    id="customSwitch4" 
                                                    :checked="row.has_download"
                                                    @click="onDownload">
                                                <label class="custom-control-label" 
                                                    for="customSwitch4"
                                                    v-html="(row.has_download) ? 'Show Download File' : 'Hide Download File'">
                                                </label>
                                            </div>
                                        </div> -->

                              <!-- <div class="col-12 row" v-if="row.has_download">
                                                <div class="form-group col-8">
                                                    <label>Download Title</label>
                                                    <input type="text" class="form-control" 
                                                        v-model="row.download_name">
                                                </div>
                                                <div class="form-group col-4">
                                                    <label>Upload File
                                                        <button v-if="row.download_preview" 
                                                            type="button" 
                                                            class="btn btn-danger btn-sm btn-pill"
                                                            style='margin-top: -10px; margin-left: 10px'
                                                            @click="row.download_preview = ''; row.download_file = ''">
                                                            Remove File
                                                        </button>
                                                    </label>
                                                    <a v-if="row.download_preview" 
                                                        :href="row.download_preview" 
                                                        target="_blank" class="pull-right">Preview
                                                    </a>
                                                    <input type="file" class="form-control" 
                                                    @change="onFileChange">
                                                </div>
                                                <div class="form-group col-md-6 col-sm-12">
                                                <label>Download Preview
                                                    <button v-if="row.download_image_preview" 
                                                        type="button" 
                                                        class="btn btn-danger btn-sm btn-pill"
                                                        style='margin-top: -10px; margin-left: 10px'
                                                        @click="row.download_image_preview = ''; 
                                                                row.download_image = ''">
                                                        Remove Image
                                                    </button>
                                                </label>
                                                <p>
                                                    <img v-if="row.download_image_preview" 
                                                        :src="row.download_image_preview"
                                                        style="max-width: 50%">
                                                </p>
                                                <input type="file" class="form-control" @change="onFilePreviewChange">
                                            </div>
                                        </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Sector</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "Update",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      editor: VueEditor,
      content: "<h1>Type your content here!</h1>",
      auth: {
        access_token: "",
      },
      row: {
        status: 1,
        view_in_home: 0,
        preview: "",
        image: "",
        base64Image: "",
        sort: 0,
        slug: "",
        title: "",
        body: "",
        bgTitle: "",
        bgColor: "",

        download_name: "",
        download_image_preview: "",
        download_image: "",
        download_preview: "",
        download_file: "",
      },

      croppieImage: "",
      cropped: false,
      program_id: this.$route.params.program_id,
      root: this.$route.params.root,
      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/sectors/" + this.$route.params.id,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.row.status = res.data.row.status;
          this.row.view_in_home = res.data.row.view_in_home;

          this.row.preview = res.data.row.image;
          this.row.base64Image = res.data.row.image;

          this.row.sort = res.data.row.sort;
          this.row.slug = res.data.row.slug;
          this.row.title = res.data.row.title;
          this.row.body = res.data.row.body;
          this.row.bgTitle = res.data.row.bgTitle;
          this.row.bgColor = res.data.row.bgColor;

          this.row.download_name = res.data.row.download_name;
          this.row.download_image_preview = res.data.row.download_image;
          this.row.download_image = res.data.row.download_image;
          this.row.download_preview = res.data.row.download_file;
          this.row.download_file = res.data.row.download_file;
        })
        .catch((err) => {
          this.pgLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/sectors/" + this.$route.params.id,
        method: "PUT",
        data: {
          program_id: this.program_id,
          parent_id: this.root,

          status: this.row.status,
          view_in_home: this.row.view_in_home,

          base64Image: this.row.base64Image,
          sort: this.row.sort,
          title: this.row.title,
          slug: this.row.slug,
          body: this.row.body,
          bgTitle: this.row.bgTitle,
          bgColor: this.row.bgColor,

          download_name: this.row.download_name,
          download_image: this.row.download_image,
          download_file: this.row.download_file,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Updated Successfully.",
          });
          this.$router.push({
            name: "sectors",
            params: { program_id: this.program_id, root: this.root },
          });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Title
    onTitleChange() {
      this.onSlugChange(this.row.title);
    },

    // on Paste
    onSlugPaste() {
      let str = this.row.slug;
      this.onSlugChange(str);
    },
    onSlugChange(str) {
      if (!str) {
        let str = this.row.slug;
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      } else {
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      }
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.base64Image = e.target.result;
      };
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.row.download_preview = URL.createObjectURL(file);
      this.createBase64File(file);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_file = e.target.result;
      };
    },
    onFilePreviewChange(e) {
      const file = e.target.files[0];
      this.row.download_image_preview = URL.createObjectURL(file);
      this.createBase64FilePreview(file);
    },
    createBase64FilePreview(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_image = e.target.result;
      };
    },

    // active status
    onStatus() {
      if (this.row.status) this.row.status = 0;
      else this.row.status = 1;
    },
    onHome() {
      if (this.row.view_in_home) this.row.view_in_home = 0;
      else this.row.view_in_home = 1;
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({
          name: "sectors",
          params: { program_id: this.program_id, root: this.root },
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
