/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Home</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="spinner-grow mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Loading -->

          <!-- Form -->
          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background 1500 * 900
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Image
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <!-- Card Content -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Content
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Title -->
                            <div class="form-group">
                              <label for="content1">Title</label>
                              <input
                                class="form-control"
                                id="content1"
                                type="text"
                                v-model="row.body1"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Button -->
                            <div class="form-group">
                              <label for="content2">Button</label>
                              <input
                                class="form-control"
                                id="content2"
                                type="text"
                                v-model="row.body2"
                              />
                            </div>
                            <!-- End Button -->

                            <!-- Body -->
                            <div class="form-group">
                              <label for="content3">Body</label>
                              <vue-editor id="content3" v-model="content" />
                            </div>
                            <!-- End Body -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->
                </div>
              </div>
            </div>

            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Home</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
          </form>
          <!-- End Form -->
        </div>
        <!-- End Content Body -->

        <Footer></Footer>
      </div>
      <!-- Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "Home",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      editor: VueEditor,
      content: "<h1>Write your content here!</h1>",
      auth: {
        access_token: "",
      },
      row: {
        preview: "",
        image: "",
        body1: "",
        body2: "",
        body3: "",
      },

      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetchRow
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/home/1",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.row.preview = res.data.row.image;
          this.row.image = res.data.row.image;
          this.row.body1 = res.data.row.body1;
          this.row.body2 = res.data.row.body2;
          this.row.body3 = res.data.row.body3;
        })
        .catch(() => {})
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/home/1",
        method: "PUT",
        data: {
          image: this.row.image,
          body1: this.row.body1,
          body2: this.row.body2,
          body3: this.row.body3,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Home Updated Successfully.",
          });
          //this.$router.push({ name: 'home' })
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>
