<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Memberships</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'memberships' }"
                  >Memberships</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">Edit</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <p><br /></p>
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p><br /></p>
            </div>
          </div>
          <!-- End Loading -->

          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabMask" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Mask')"
                            aria-expanded="false"
                            aria-controls="collapseMask"
                            data-toggle="collapse"
                            data-target="#collapseMask"
                          >
                            Mask
                            <span
                              id="iconToggleMask"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseMask"
                          class="collapse"
                          aria-labelledby="TabMask"
                          data-parent="#accordion"
                        >
                          <div class="col-12 row pt-3">
                            <!-- Title -->
                            <div class="form-group col-10">
                              <label for="input1">background Title</label>
                              <vue-editor
                                id="editor0001"
                                v-model="row.bgTitle"
                                :init="{
                                  height: 200,
                                }"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group col-2">
                              <label for="input2">background Color</label>
                              <input
                                class="form-control"
                                id="input2"
                                type="color"
                                style="height: 200px"
                                v-model="row.bgColor"
                              />
                            </div>
                            <!-- End Slug -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Page Name
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1">Title</label>
                              <input
                                class="form-control"
                                id="input1"
                                type="text"
                                v-model="row.title"
                                @keyup="onTitleChange"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group">
                              <label for="input2">Slug</label>
                              <input
                                class="form-control text-lowercase"
                                id="input2"
                                type="text"
                                v-model="row.slug"
                                @keydown.space.prevent
                                @paste="onSlugPaste"
                                @change="onSlugChange(false)"
                              />
                            </div>
                            <!-- End Slug -->

                            <!-- Sort -->
                            <div class="form-group">
                              <label for="input3">Sort</label>
                              <input
                                class="form-control"
                                id="input3"
                                min="0"
                                type="number"
                                v-model.number="row.sort"
                              />
                            </div>
                            <!-- End Sort -->

                            <h4><br />Status & Visibility</h4>
                            <div class="col-md-12">
                              <!-- Status -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    :checked="row.status"
                                    @click="onStatus"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch1"
                                    v-html="row.status ? 'Active' : 'Inactive'"
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Status -->

                              <!-- Training -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch2"
                                    :checked="row.has_application"
                                    @click="onApplication"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch2"
                                    v-html="
                                      row.has_application
                                        ? 'Show Application'
                                        : 'Hide Application'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Training -->

                              <div
                                class="col-12 row"
                                v-if="row.has_application"
                              >
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Application Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.application_name"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Application Type</label>
                                  <select
                                    class="form-control"
                                    v-model="row.application_path"
                                  >
                                    <option value="trainings-applications">
                                      Training Application
                                    </option>
                                    <option value="members-applications">
                                      Membership Application
                                    </option>
                                    <option value="instructor-applications">
                                      Iam Instructor Application
                                    </option>
                                    <option value="experience-applications">
                                      I've Experience Application
                                    </option>
                                    <option value="accreditation-applications">
                                      Accreditation Application
                                    </option>
                                    <option value="certificate-applications">
                                      Certificate Application
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <!-- Faq -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch3"
                                    :checked="row.has_faq"
                                    @click="onFaq"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch3"
                                    v-html="
                                      row.has_faq ? 'Show FAQ' : 'Hide FAQ'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 row" v-if="row.has_faq">
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>FAQ Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.faq_link"
                                  />
                                </div>
                              </div>
                              <!-- End Faq -->

                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch33"
                                    :checked="row.has_payment"
                                    @click="onPayment"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch33"
                                    v-html="
                                      row.has_payment
                                        ? 'Show Payment'
                                        : 'Hide Payment'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 row" v-if="row.has_payment">
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Payment Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.payment_name"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Payment Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.payment_link"
                                  />
                                </div>
                              </div>

                              <!-- Faq -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch4"
                                    :checked="row.has_download"
                                    @click="onDownload"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch4"
                                    v-html="
                                      row.has_download
                                        ? 'Show Download File'
                                        : 'Hide Download File'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Faq -->

                              <div class="col-12 row" v-if="row.has_download">
                                <div class="form-group col-8">
                                  <label>Download Title</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.download_name"
                                  />
                                </div>
                                <div class="form-group col-4">
                                  <label
                                    >Download File
                                    <button
                                      v-if="row.download_preview"
                                      type="button"
                                      class="btn btn-danger btn-sm btn-pill"
                                      style="
                                        margin-top: -10px;
                                        margin-left: 10px;
                                      "
                                      @click="
                                        row.download_preview = '';
                                        row.download_file = '';
                                      "
                                    >
                                      Remove File
                                    </button>
                                  </label>
                                  <a
                                    v-if="row.download_preview"
                                    :href="row.download_preview"
                                    target="_blank"
                                    class="pull-right"
                                    >Preview
                                  </a>
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="onFileChange"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label
                                    >Download Preview 180 * 220
                                    <button
                                      v-if="row.download_image_preview"
                                      type="button"
                                      class="btn btn-danger btn-sm btn-pill"
                                      style="
                                        margin-top: -10px;
                                        margin-left: 10px;
                                      "
                                      @click="
                                        row.download_image_preview = '';
                                        row.download_image = '';
                                      "
                                    >
                                      Remove Image
                                    </button>
                                  </label>
                                  <p>
                                    <img
                                      v-if="row.download_image_preview"
                                      :src="row.download_image_preview"
                                      style="max-width: 50%"
                                    />
                                  </p>
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="onFilePreviewChange"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContents" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Contents')"
                            aria-expanded="false"
                            aria-controls="collapseContents"
                            data-toggle="collapse"
                            data-target="#collapseContents"
                          >
                            Content
                            <span
                              id="iconToggleContents"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContents"
                          class="collapse"
                          aria-labelledby="TabContents"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Body -->
                            <div class="form-group">
                              <label for="editor1">Body1</label>
                              <vue-editor
                                id="editor1"
                                v-model="row.body1"
                                :init="{
                                  height: 300,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <div class="col-12 row" style="padding: 0">
                              <!-- Body -->
                              <div class="form-group col-4">
                                <label for="editor2">Body2</label>
                                <vue-editor
                                  id="editor2"
                                  v-model="row.body2"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-4">
                                <label for="editor3">Body3</label>
                                <vue-editor
                                  id="editor3"
                                  v-model="row.body3"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-4">
                                <label for="editor4">Body4</label>
                                <vue-editor
                                  id="editor4"
                                  v-model="row.body4"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->
                            </div>

                            <!-- Body -->
                            <div class="form-group">
                              <label for="editor5">Body5</label>
                              <vue-editor
                                id="editor5"
                                v-model="row.body5"
                                :init="{
                                  height: 300,
                                }"
                              />
                            </div>
                            <!-- End Body -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabDesgin1" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Desgin1')"
                            aria-expanded="false"
                            aria-controls="collapseDesgin1"
                            data-toggle="collapse"
                            data-target="#collapseDesgin1"
                          >
                            Desgin 1
                            <span
                              id="iconToggleDesgin1"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseDesgin1"
                          class="collapse"
                          aria-labelledby="TabDesgin1"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="iEditor1">Part 1</label>
                                <vue-editor
                                  id="iEditor1"
                                  v-model="row.body1_1"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for=""
                                  >Image 1 ( 660 * 390 )
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    v-if="row.preview1_1"
                                    @click="
                                      row.preview1_1 = '';
                                      row.image1_1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="row.preview1_1 ? row.preview1_1 : ''"
                                  style="width: 100%; height: 250px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage1_1Change"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for=""
                                  >Image 2 ( 660 * 390 )
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    v-if="row.preview1_2"
                                    @click="
                                      row.preview1_2 = '';
                                      row.image1_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="row.preview1_2 ? row.preview1_2 : ''"
                                  style="width: 100%; height: 250px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage1_2Change"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="iEditor2">Part 2</label>
                                <vue-editor
                                  id="iEditor2"
                                  v-model="row.body1_2"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="iEditor3">Part 3</label>
                                <vue-editor
                                  id="iEditor3"
                                  v-model="row.body1_3"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for=""
                                  >Image 3 ( 660 * 390 )
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    v-if="row.preview1_3"
                                    @click="
                                      row.preview1_3 = '';
                                      row.image1_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="row.preview1_3 ? row.preview1_3 : ''"
                                  style="width: 100%; height: 250px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage1_3Change"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for=""
                                  >Image 4 ( 660 * 390 )
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    v-if="row.preview1_4"
                                    @click="
                                      row.preview1_4 = '';
                                      row.image1_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="row.preview1_4 ? row.preview1_4 : ''"
                                  style="width: 100%; height: 250px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage1_4Change"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="iEditor4">Part 4</label>
                                <vue-editor
                                  id="iEditor4"
                                  v-model="row.body1_4"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabDesgin2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Desgin2')"
                            aria-expanded="false"
                            aria-controls="collapseDesgin2"
                            data-toggle="collapse"
                            data-target="#collapseDesgin2"
                          >
                            Desgin 2
                            <span
                              id="iconToggleDesgin2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseDesgin2"
                          class="collapse"
                          aria-labelledby="TabDesgin2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for=""
                                  >Image 1 ( 660 * 390 )
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    v-if="row.preview2_1"
                                    @click="
                                      row.preview2_1 = '';
                                      row.image2_1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="row.preview2_1 ? row.preview2_1 : ''"
                                  style="width: 100%; height: 250px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_1Change"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">Label 1</label>
                                <vue-editor
                                  id="editorL1"
                                  v-model="row.label2_1"
                                  :init="{
                                    height: 260,
                                  }"
                                />
                                <!-- <textarea class="form-control" 
                                                rows="6" 
                                                v-model="row.label2_1"
                                                style="font-size: 32px">
                                        </textarea> -->
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color2_1"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="i2Editor1">Part 1 left</label>
                                <vue-editor
                                  id="i2Editor1"
                                  v-model="row.body2_1"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="i2Editor1_r">Part 1 Right</label>
                                <vue-editor
                                  id="i2Editor1_r"
                                  v-model="row.body2_1_r"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">Label 2</label>
                                <vue-editor
                                  id="editorL2"
                                  v-model="row.label2_2"
                                  :init="{
                                    height: 260,
                                  }"
                                />
                                <!-- <textarea class="form-control" 
                                                rows="6" 
                                                v-model="row.label2_2"
                                                style="font-size: 32px">
                                        </textarea> -->
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color2_2"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for=""
                                  >Image 2 ( 660 * 390 )
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    v-if="row.preview2_2"
                                    @click="
                                      row.preview2_2 = '';
                                      row.image2_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="row.preview2_2 ? row.preview2_2 : ''"
                                  style="width: 100%; height: 250px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_2Change"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="i2Editor2">Part 2 Left</label>
                                <vue-editor
                                  id="i2Editor2"
                                  v-model="row.body2_2"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="i2Editor2_r">Part 2 Right</label>
                                <vue-editor
                                  id="i2Editor2_r"
                                  v-model="row.body2_2_r"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for=""
                                  >Image 3 ( 660 * 390 )
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    v-if="row.preview2_3"
                                    @click="
                                      row.preview2_3 = '';
                                      row.image2_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="row.preview2_3 ? row.preview2_3 : ''"
                                  style="width: 100%; height: 250px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_3Change"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">Label 3</label>
                                <vue-editor
                                  id="editorL3"
                                  v-model="row.label2_3"
                                  :init="{
                                    height: 260,
                                  }"
                                />
                                <!-- <textarea class="form-control" 
                                                rows="6" 
                                                v-model="row.label2_3"
                                                style="font-size: 32px">
                                        </textarea> -->
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color2_3"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="i2Editor3">Part 3 Left</label>
                                <vue-editor
                                  id="i2Editor3"
                                  v-model="row.body2_3"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="i2Editor3_r">Part 3 Right</label>
                                <vue-editor
                                  id="i2Editor3_r"
                                  v-model="row.body2_3_r"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">Label 4</label>
                                <vue-editor
                                  id="editorL4"
                                  v-model="row.label2_4"
                                  :init="{
                                    height: 260,
                                  }"
                                />
                                <!-- <textarea class="form-control" 
                                                rows="6" 
                                                v-model="row.label2_4"
                                                style="font-size: 32px">
                                        </textarea> -->
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color2_4"
                                />
                              </div>
                              <!-- End Body -->

                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for=""
                                  >Image 4 ( 660 * 390 )
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    v-if="row.preview2_4"
                                    @click="
                                      row.preview2_4 = '';
                                      row.image2_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="row.preview2_4 ? row.preview2_4 : ''"
                                  style="width: 100%; height: 250px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_4Change"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="i2Editor4">Part 4 Left</label>
                                <vue-editor
                                  id="i2Editor4"
                                  v-model="row.body2_4"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="i2Editor4_r">Part 4 Right</label>
                                <vue-editor
                                  id="i2Editor4_r"
                                  v-model="row.body2_4_r"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Membership</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "Edit",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        status: 1,
        has_faq: 0,
        has_application: 0,
        has_download: 0,
        has_payment: 0,

        faq_link: "",
        payment_name: "",
        payment_link: "",

        download_name: "",
        download_image_preview: "",
        download_image: "",
        download_preview: "",
        download_file: "",

        application_name: "",
        application_path: "",

        preview: "",
        image: "",
        base64Image: "",
        sort: 0,
        slug: "",
        title: "",
        body: "",
        bgTitle: "",
        bgColor: "",

        // f@K
        body1: "",
        body2: "",
        body3: "",
        body4: "",
        body5: "",

        // des1
        body2_1: "",
        body2_1_r: "",
        preview2_1: "",
        image2_1: "",
        label2_1: "",
        color2_1: "",

        body2_2: "",
        body2_2_r: "",
        preview2_2: "",
        image_2_2: "",
        label2_2: "",
        color2_2: "",

        body2_3: "",
        body2_3_r: "",
        preview2_3: "",
        image_2_3: "",
        label2_3: "",
        color2_3: "",

        body2_4: "",
        body2_4_r: "",
        preview2_4: "",
        image_2_4: "",
        label2_4: "",
        color2_4: "",

        body1_1: "",
        preview1_1: "",
        image1_1: "",

        body1_2: "",
        preview1_2: "",
        image1_2: "",

        body1_3: "",
        preview1_3: "",
        image1_3: "",

        body1_4: "",
        preview1_4: "",
        image1_4: "",
      },

      croppieImage: "",
      cropped: false,

      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/memberships/" + this.$route.params.id,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.status = res.data.row.status;
          this.row.has_faq = res.data.row.has_faq;
          this.row.has_application = res.data.row.has_application;
          this.row.has_download = res.data.row.has_download;
          this.row.has_payment = res.data.row.has_payment;

          this.row.faq_link = res.data.row.faq_link;
          this.row.payment_name = res.data.row.payment_name;
          this.row.payment_link = res.data.row.payment_link;

          this.row.download_name = res.data.row.download_name;
          this.row.download_image_preview = res.data.row.download_image;
          this.row.download_image = res.data.row.download_image;
          this.row.download_preview = res.data.row.download_file;
          this.row.download_file = res.data.row.download_file;

          this.row.application_name = res.data.row.application_name;
          this.row.application_path = res.data.row.application_path;

          this.row.preview = res.data.row.image;
          this.row.image = res.data.row.image;
          this.row.sort = res.data.row.sort;
          this.row.slug = res.data.row.slug;
          this.row.title = res.data.row.title;
          this.row.body = res.data.row.body;
          this.row.bgTitle = res.data.row.bgTitle;
          this.row.bgColor = res.data.row.bgColor;

          this.row.body1 = res.data.row.body1;
          this.row.body2 = res.data.row.body2;
          this.row.body3 = res.data.row.body3;
          this.row.body4 = res.data.row.body4;
          this.row.body5 = res.data.row.body5;

          this.row.body1_1 = res.data.row.body1_1;
          this.row.preview1_1 = res.data.row.image1_1;
          this.row.image1_1 = res.data.row.image1_1;

          this.row.body1_2 = res.data.row.body1_2;
          this.row.preview1_2 = res.data.row.image1_2;
          this.row.image1_2 = res.data.row.image1_2;

          this.row.body1_3 = res.data.row.body1_3;
          this.row.preview1_3 = res.data.row.image1_3;
          this.row.image1_3 = res.data.row.image1_3;

          this.row.body1_4 = res.data.row.body1_4;
          this.row.preview1_4 = res.data.row.image1_4;
          this.row.image1_4 = res.data.row.image1_4;

          this.row.body2_1 = res.data.row.body2_1;
          this.row.body2_1_r = res.data.row.body2_1_r;
          this.row.preview2_1 = res.data.row.image2_1;
          this.row.image2_1 = res.data.row.image2_1;
          this.row.label2_1 = res.data.row.label2_1;
          this.row.color2_1 = res.data.row.color2_1;

          this.row.body2_2 = res.data.row.body2_2;
          this.row.body2_2_r = res.data.row.body2_2_r;
          this.row.preview2_2 = res.data.row.image2_2;
          this.row.image2_2 = res.data.row.image2_2;
          this.row.label2_2 = res.data.row.label2_2;
          this.row.color2_2 = res.data.row.color2_2;

          this.row.body2_3 = res.data.row.body2_3;
          this.row.body2_3_r = res.data.row.body2_3_r;
          this.row.preview2_3 = res.data.row.image2_3;
          this.row.image2_3 = res.data.row.image2_3;
          this.row.label2_3 = res.data.row.label2_3;
          this.row.color2_3 = res.data.row.color2_3;

          this.row.body2_4 = res.data.row.body2_4;
          this.row.body2_4_r = res.data.row.body2_4_r;
          this.row.preview2_4 = res.data.row.image2_4;
          this.row.image2_4 = res.data.row.image2_4;
          this.row.label2_4 = res.data.row.label2_4;
          this.row.color2_4 = res.data.row.color2_4;
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // edit Row
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/memberships/" + this.$route.params.id,
        method: "PUT",
        data: {
          status: this.row.status,
          has_faq: this.row.has_faq,
          has_application: this.row.has_application,
          has_download: this.row.has_download,
          has_payment: this.row.has_payment,

          faq_link: this.row.faq_link,
          payment_name: this.row.payment_name,
          payment_link: this.row.payment_link,

          download_name: this.row.download_name,
          download_image: this.row.download_image,
          download_file: this.row.download_file,

          application_name: this.row.application_name,
          application_path: this.row.application_path,

          image: this.row.image,
          sort: this.row.sort,
          title: this.row.title,
          slug: this.row.slug,
          body: this.row.body,
          bgTitle: this.row.bgTitle,
          bgColor: this.row.bgColor,

          body1: this.row.body1,
          body2: this.row.body2,
          body3: this.row.body3,
          body4: this.row.body4,
          body5: this.row.body5,

          body1_1: this.row.body1_1,
          image1_1: this.row.image1_1,
          body1_2: this.row.body1_2,
          image1_2: this.row.image1_2,
          body1_3: this.row.body1_3,
          image1_3: this.row.image1_3,
          body1_4: this.row.body1_4,
          image1_4: this.row.image1_4,

          body2_1: this.row.body2_1,
          body2_1_r: this.row.body2_1_r,
          image2_1: this.row.image2_1,
          label2_1: this.row.label2_1,
          color2_1: this.row.color2_1,

          body2_2: this.row.body2_2,
          body2_2_r: this.row.body2_2_r,
          image2_2: this.row.image2_2,
          label2_2: this.row.label2_2,
          color2_2: this.row.color2_2,

          body2_3: this.row.body2_3,
          body2_3_r: this.row.body2_3_r,
          image2_3: this.row.image2_3,
          label2_3: this.row.label2_3,
          color2_3: this.row.color2_3,

          body2_4: this.row.body2_4,
          body2_4_r: this.row.body2_4_r,
          image2_4: this.row.image2_4,
          label2_4: this.row.label2_4,
          color2_4: this.row.color2_4,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Updated Successfully.",
          });
          this.$router.push({ name: "memberships" });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Title
    onTitleChange() {
      this.onSlugChange(this.row.title);
    },

    // on Paste
    onSlugPaste() {
      let str = this.row.slug;
      this.onSlugChange(str);
    },
    onSlugChange(str) {
      if (!str) {
        let str = this.row.slug;
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      } else {
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      }
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    // d1
    onImage1_1Change(e) {
      const file = e.target.files[0];
      this.row.preview1_1 = URL.createObjectURL(file);
      this.createBase64Image1_1(file);
    },
    createBase64Image1_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_1 = e.target.result;
      };
    },
    onImage1_2Change(e) {
      const file = e.target.files[0];
      this.row.preview1_2 = URL.createObjectURL(file);
      this.createBase64Image1_2(file);
    },
    createBase64Image1_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_2 = e.target.result;
      };
    },
    onImage1_3Change(e) {
      const file = e.target.files[0];
      this.row.preview1_3 = URL.createObjectURL(file);
      this.createBase64Image1_3(file);
    },
    createBase64Image1_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_3 = e.target.result;
      };
    },
    onImage1_4Change(e) {
      const file = e.target.files[0];
      this.row.preview1_4 = URL.createObjectURL(file);
      this.createBase64Image1_4(file);
    },
    createBase64Image1_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_4 = e.target.result;
      };
    },

    // d2

    onImage2_1Change(e) {
      const file = e.target.files[0];
      this.row.preview2_1 = URL.createObjectURL(file);
      this.createBase64Image2_1(file);
    },
    createBase64Image2_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_1 = e.target.result;
      };
    },
    onImage2_2Change(e) {
      const file = e.target.files[0];
      this.row.preview2_2 = URL.createObjectURL(file);
      this.createBase64Image2_2(file);
    },
    createBase64Image2_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_2 = e.target.result;
      };
    },
    onImage2_3Change(e) {
      const file = e.target.files[0];
      this.row.preview2_3 = URL.createObjectURL(file);
      this.createBase64Image2_3(file);
    },
    createBase64Image2_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_3 = e.target.result;
      };
    },
    onImage2_4Change(e) {
      const file = e.target.files[0];
      this.row.preview2_4 = URL.createObjectURL(file);
      this.createBase64Image2_4(file);
    },
    createBase64Image2_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_4 = e.target.result;
      };
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.row.download_preview = URL.createObjectURL(file);
      this.createBase64File(file);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_file = e.target.result;
      };
    },

    onFilePreviewChange(e) {
      const file = e.target.files[0];
      this.row.download_image_preview = URL.createObjectURL(file);
      this.createBase64FilePreview(file);
    },
    createBase64FilePreview(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_image = e.target.result;
      };
    },

    // active status
    onStatus() {
      if (this.row.status) this.row.status = 0;
      else this.row.status = 1;
    },
    onFaq() {
      if (this.row.has_faq) this.row.has_faq = 0;
      else this.row.has_faq = 1;
    },
    onPayment() {
      if (this.row.has_payment) this.row.has_payment = 0;
      else this.row.has_payment = 1;
    },
    onApplication() {
      if (this.row.has_application) this.row.has_application = 0;
      else this.row.has_application = 1;
    },
    onDownload() {
      if (this.row.has_download) this.row.has_download = 0;
      else this.row.has_download = 1;
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "memberships" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
