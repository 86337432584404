/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Popular Searchs (Shortcut)</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link
                  :to="{ name: 'searchs-sub', params: { root_id: root_id } }"
                  >Popular Searchs (Shortcut)</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add New
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <form
            @submit.prevent="addNew"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <!--  <div class="card">
                            <div class="card-body">
                                <div id="accordion" class="accordion">
                                    <div id="TabBackground" class="card-header">
                                        <h2 class="h4 card-header-title" 
                                            @click="collapseToggle('Background')"
                                            aria-expanded="false" 
                                            aria-controls="collapseBackground" 
                                            data-toggle="collapse"
                                            data-target="#collapseBackground">Background
                                            <span id="iconToggleBackground" 
                                                class="ti-angle-up u-sidebar-nav-menu__item-arrow 
                                                    pull-right black">
                                            </span>
                                        </h2>
                                    </div>
                                    <div id="collapseBackground" 
                                        class="collapse show" 
                                        aria-labelledby="TabBackground" 
                                        data-parent="#accordion">
                                        <div class="col-12 pt-3">
                                        
                                        <div class="form-group">
                                            <label>Background
                                                <button v-if="row.preview" 
                                                    type="button" 
                                                    class="btn btn-danger btn-sm btn-pill"
                                                    style='margin-top: -10px; margin-left: 10px'
                                                    @click="row.preview = ''; row.image = ''">
                                                    Remove Image
                                                </button>
                                            </label>
                                            <p><img v-if="row.preview" 
                                                :src="row.preview" 
                                                style="max-width: 100%">
                                            </p>
                                            <input type="file" 
                                                class="form-control" 
                                                accept="image/*"
                                                v-on:change="onImageChange">
                                        </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div> -->
                  <!-- End Card Background -->

                  <!-- Card Mask -->
                  <!-- <div class="card mt-5">
                        <div class="card-body">
                            <div id="accordion" class="accordion">
                                <div id="TabMask" class="card-header">
                                    <h2 class="h4 card-header-title" 
                                        @click="collapseToggle('Mask')"
                                        aria-expanded="false" 
                                        aria-controls="collapseMask" 
                                        data-toggle="collapse"
                                        data-target="#collapseMask">Mask
                                        <span id="iconToggleMask" 
                                            class="ti-angle-up u-sidebar-nav-menu__item-arrow 
                                                pull-right black">
                                        </span>
                                    </h2>
                                </div>
                                <div id="collapseMask" 
                                    class="collapse" 
                                    aria-labelledby="TabMask" 
                                    data-parent="#accordion">

                                <div class="col-12 row pt-3">
                                    
                                    <div class="form-group col-9">
                                        <label for="input1">background Title</label>
                                        <vue-editor
                                            id="editor01"
                                            v-model="row.bgTitle"
                                            
                                            :init="{
                                                height: 200,
                                                menubar: editor.menubar,
                                                plugins: editor.plugins,
                                                toolbar: editor.toolbar
                                            }"
                                        />
                                    </div>

                                    <div class="form-group col-3">
                                        <label for="input2">background Color</label>
                                        <input class="form-control"
                                                id="input2"  
                                                type="color" 
                                                style="height: 200px" 
                                                v-model="row.bgColor">
                                    </div>

                                    
                                </div>
                                
                                </div>
                            </div>
                        </div>
                    </div> -->
                  <!-- End Card Mask -->

                  <!-- Card  -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Page Name
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1">Title</label>
                              <input
                                class="form-control"
                                id="input1"
                                type="text"
                                v-model="row.title"
                                @keyup="onTitleChange"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group">
                              <label for="input2">Slug</label>
                              <input
                                class="form-control text-lowercase"
                                id="input2"
                                type="text"
                                v-model="row.slug"
                                @keydown.space.prevent
                                @paste="onSlugPaste"
                                @change="onSlugChange(false)"
                              />
                            </div>
                            <!-- End Slug -->

                            <!-- Sort -->
                            <div class="form-group">
                              <label for="input3">Sort</label>
                              <input
                                class="form-control"
                                id="input3"
                                min="0"
                                type="number"
                                v-model.number="row.sort"
                              />
                            </div>
                            <!-- End Sort -->

                            <h4><br />Status & Visibility</h4>
                            <div class="col-md-12">
                              <!-- Status -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    :checked="row.status"
                                    @click="onStatus"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch1"
                                    v-html="row.status ? 'Active' : 'Inactive'"
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Status -->

                              <!-- Training -->
                              <!-- <div class="form-group">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                    class="custom-control-input" 
                                                    id="customSwitch2" 
                                                    :checked="row.has_application"
                                                    @click="onApplication">
                                                <label class="custom-control-label" 
                                                    for="customSwitch2"
                                                    v-html="(row.has_application) 
                                                        ? 'Show Application' 
                                                        : 'Hide Application'">
                                                </label>
                                            </div>
                                        </div> -->
                              <!-- End Training -->

                              <!--  <div class="col-12 row" v-if="row.has_application">
                                            <div class="form-group col-md-6 col-sm-12">
                                                <label>Application Name</label>
                                                <input type="text" class="form-control" 
                                                    v-model="row.application_name">
                                            </div>
                                            <div class="form-group col-md-6 col-sm-12">
                                                <label>Application Type</label>
                                                <select class="form-control" 
                                                    v-model="row.application_path">
                                                    <option value="trainings-applications">
                                                        Training Application
                                                    </option>
                                                    <option value="members-applications">
                                                        Membership Application
                                                    </option>
                                                    <option value="instructor-applications">
                                                        Iam Instructor Application
                                                    </option>
                                                    <option value="experience-applications">
                                                        I've Experience Application
                                                    </option>
                                                </select>
                                            </div>
                                        </div> -->

                              <!-- Faq -->
                              <!-- <div class="form-group">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                    class="custom-control-input" 
                                                    id="customSwitch3" 
                                                    :checked="row.has_faq"
                                                    @click="onFaq">
                                                <label class="custom-control-label" 
                                                    for="customSwitch3"
                                                    v-html="(row.has_faq) ? 'Show FAQ' : 'Hide FAQ'">
                                                </label>
                                            </div>
                                        </div> -->
                              <!-- End Faq -->

                              <!-- Faq -->
                              <!-- <div class="form-group">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                    class="custom-control-input" 
                                                    id="customSwitch33" 
                                                    :checked="row.has_programs"
                                                    @click="onPrograms">
                                                <label class="custom-control-label" 
                                                    for="customSwitch33"
                                                    v-html="(row.has_programs) ? 'Show Programs' : 'Hide Programs'">
                                                </label>
                                            </div>
                                        </div> -->
                              <!-- End Faq -->

                              <!-- Faq -->
                              <!-- <div class="form-group">
                                            <div class="custom-control custom-switch mb-2">
                                                <input type="checkbox" 
                                                    class="custom-control-input" 
                                                    id="customSwitch4" 
                                                    :checked="row.has_download"
                                                    @click="onDownload">
                                                <label class="custom-control-label" 
                                                    for="customSwitch4"
                                                    v-html="(row.has_download) 
                                                    ? 'Show Download File' : 'Hide Download File'">
                                                </label>
                                            </div>
                                        </div> -->
                              <!-- End Faq -->

                              <!-- <div class="col-12 row" v-if="row.has_download">
                                            <div class="form-group col-8">
                                                <label>Download Title</label>
                                                <input type="text" class="form-control" 
                                                    v-model="row.download_name">
                                            </div>
                                            <div class="form-group col-4">
                                                <label>Download File
                                                    <button v-if="row.download_preview" 
                                                        type="button" 
                                                        class="btn btn-danger btn-sm btn-pill"
                                                        style='margin-top: -10px; margin-left: 10px'
                                                        @click="row.download_preview = ''; 
                                                                row.download_file = ''">
                                                        Remove File
                                                    </button>
                                                </label>
                                                <a v-if="row.download_preview" 
                                                        :href="row.download_preview" 
                                                        target="_blank" class="pull-right">Preview
                                                    </a>
                                                <input type="file" class="form-control" 
                                                @change="onFileChange">
                                            </div>
                                            <div class="form-group col-md-6 col-sm-12">
                                                <label>Download Image
                                                    <button v-if="row.download_image_preview" 
                                                        type="button" 
                                                        class="btn btn-danger btn-sm btn-pill"
                                                        style='margin-top: -10px; margin-left: 10px'
                                                        @click="row.download_image_preview = ''; 
                                                                row.download_image = ''">
                                                        Remove Image
                                                    </button>
                                                </label>
                                                <p>
                                                    <img v-if="row.download_image_preview" 
                                                        :src="row.download_image_preview"
                                                        style="max-width: 50%">
                                                </p>
                                                <input type="file" class="form-control" 
                                                @change="onFilePreviewChange" accept="image/*">
                                            </div>
                                        </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End -->

                  <!-- Card Desgin 1 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent1" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content1')"
                            aria-expanded="false"
                            aria-controls="collapseContent1"
                            data-toggle="collapse"
                            data-target="#collapseContent1"
                          >
                            Group 1
                            <span
                              id="iconToggleContent1"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent1"
                          class="collapse"
                          aria-labelledby="TabContent1"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <vue-editor
                                id="editorBody1_1"
                                v-model="content"
                              />
                            </div>

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview1_2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview1_2 = '';
                                      row.image1_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 *390</label>
                                <img
                                  :src="row.preview1_2 ? row.preview1_2 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage1_2Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line1_2"
                                />
                                <vue-editor
                                  id="editorMask1_2"
                                  v-model="content"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color1_2"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody1_2_1"
                                  v-model="content"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody1_2_2"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line1_3"
                                />
                                <vue-editor
                                  id="editorMask1_3"
                                  v-model="row.mask1_3"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color1_3"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview1_3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview1_3 = '';
                                      row.image1_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview1_3 ? row.preview1_3 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage1_3Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody1_3_1"
                                  v-model="row.body1_3_1"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody1_3_2"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview1_4"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview1_4 = '';
                                      row.image1_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview1_4 ? row.preview1_4 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage1_4Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line1_4"
                                />
                                <vue-editor
                                  id="editorMask1_4"
                                  v-model="content"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color1_4"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody1_4_1"
                                  v-model="row.body1_4_1"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody1_4_2"
                                  v-model="row.body1_4_2"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line1_5"
                                />
                                <vue-editor
                                  id="editorMask1_5"
                                  v-model="content"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color1_5"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview1_5"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview1_5 = '';
                                      row.image1_5 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview1_5 ? row.preview1_5 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage1_5Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody1_5_1"
                                  v-model="content"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody1_5_2"
                                  v-model="content"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 1 -->

                  <!-- Card Desgin 2 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content2')"
                            aria-expanded="false"
                            aria-controls="collapseContent2"
                            data-toggle="collapse"
                            data-target="#collapseContent2"
                          >
                            Group 2
                            <span
                              id="iconToggleContent2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent2"
                          class="collapse"
                          aria-labelledby="TabContent2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_1"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_1 = '';
                                      row.image2_1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_1 ? row.preview2_1 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_1Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line2_1"
                                />
                                <vue-editor
                                  id="editorMask2_1"
                                  v-model="row.mask2_1"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color2_1"
                                />
                              </div>
                              <div class="form-group col-12">
                                <vue-editor
                                  id="editorBody2_1"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line2_2"
                                />
                                <vue-editor
                                  id="editorMask2_2"
                                  v-model="row.mask2_2"
                                  :init="{
                                    height: 215,
                                    menubar: editor.menubar,
                                    plugins: editor.plugins,
                                    toolbar: editor.toolbar,
                                  }"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color2_2"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_2 = '';
                                      row.image2_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_2 ? row.preview2_2 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_2Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-12">
                                <vue-editor
                                  id="editorBody2_2"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_3 = '';
                                      row.image2_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_3 ? row.preview2_3 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_3Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line2_3"
                                />
                                <vue-editor
                                  id="editorMask2_3"
                                  v-model="content"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="content"
                                />
                              </div>
                              <div class="form-group col-12">
                                <vue-editor
                                  id="editorBody2_3"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line2_4"
                                />
                                <vue-editor
                                  id="editorMask2_4"
                                  v-model="content"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color2_4"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview2_4"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2_4 = '';
                                      row.image2_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview2_4 ? row.preview2_4 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage2_4Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-12">
                                <vue-editor
                                  id="editorBody2_4"
                                  v-model="content"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 2 -->

                  <!-- Card Desgin 3 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content3')"
                            aria-expanded="false"
                            aria-controls="collapseContent3"
                            data-toggle="collapse"
                            data-target="#collapseContent3"
                          >
                            Group 3
                            <span
                              id="iconToggleContent3"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent3"
                          class="collapse"
                          aria-labelledby="TabContent3"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div
                              class="col-12 row"
                              style="padding: 0; margin-left: unset"
                            >
                              <div
                                class="form-group col-4"
                                style="padding-left: 0"
                              >
                                <label for="">
                                  <button
                                    v-if="row.preview3_1"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_1 = '';
                                      row.image3_1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_1 ? row.preview3_1 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_1Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_1"
                                  v-model="content"
                                />
                              </div>

                              <div class="form-group col-4">
                                <label for="">
                                  <button
                                    v-if="row.preview3_2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_2 = '';
                                      row.image3_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_2 ? row.preview3_2 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_2Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_2"
                                  v-model="content"
                                />
                              </div>

                              <div
                                class="form-group col-4"
                                style="padding-right: 0"
                              >
                                <label for="">
                                  <button
                                    v-if="row.preview3_3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_3 = '';
                                      row.image3_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_3 ? row.preview3_3 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_3Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_3"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div
                              class="col-12 row"
                              style="padding: 0; margin-left: unset"
                            >
                              <div
                                class="form-group col-4"
                                style="padding-left: 0"
                              >
                                <label for="">
                                  <button
                                    v-if="row.preview3_4"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_4 = '';
                                      row.image3_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_4 ? row.preview3_4 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_4Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_4"
                                  v-model="content"
                                />
                              </div>

                              <div class="form-group col-4">
                                <label for="">
                                  <button
                                    v-if="row.preview3_5"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_5 = '';
                                      row.image3_5 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_5 ? row.preview3_5 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_5Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_5"
                                  v-model="content"
                                />
                              </div>

                              <div
                                class="form-group col-4"
                                style="padding-right: 0"
                              >
                                <label for="">
                                  <button
                                    v-if="row.preview3_6"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_6 = '';
                                      row.image3_6 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_6 ? row.preview3_6 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_6Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_6"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div
                              class="col-12 row"
                              style="padding: 0; margin-left: unset"
                            >
                              <div
                                class="form-group col-4"
                                style="padding-left: 0"
                              >
                                <label for="">
                                  <button
                                    v-if="row.preview3_7"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_7 = '';
                                      row.image3_7 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_7 ? row.preview3_7 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_7Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_7"
                                  v-model="content"
                                />
                              </div>

                              <div class="form-group col-4">
                                <label for="">
                                  <button
                                    v-if="row.preview3_8"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_8 = '';
                                      row.image3_8 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_8 ? row.preview3_8 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_8Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_8"
                                  v-model="content"
                                />
                              </div>

                              <div
                                class="form-group col-4"
                                style="padding-right: 0"
                              >
                                <label for="">
                                  <button
                                    v-if="row.preview3_9"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3_9 = '';
                                      row.image3_9 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview3_9 ? row.preview3_9 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage3_9Change"
                                  accept="image/*"
                                />
                                <vue-editor
                                  id="editorBody3_9"
                                  v-model="content"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 3 -->

                  <!-- Card Desgin 4 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent4" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content4')"
                            aria-expanded="false"
                            aria-controls="collapseContent4"
                            data-toggle="collapse"
                            data-target="#collapseContent4"
                          >
                            Group 4
                            <span
                              id="iconToggleContent4"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent4"
                          class="collapse"
                          aria-labelledby="TabContent4"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody4_1"
                                  v-model="content"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview4_1"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview4_1 = '';
                                      row.image4_1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview4_1 ? row.preview4_1 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage4_1Change"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview4_2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview4_2 = '';
                                      row.image4_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview4_2 ? row.preview4_2 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage4_2Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody4_2"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody4_3"
                                  v-model="content"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview4_3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview4_3 = '';
                                      row.image4_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview4_3 ? row.preview4_3 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage4_3Change"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview4_4"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview4_4 = '';
                                      row.image4_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview4_4 ? row.preview4_4 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage4_4Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody4_4"
                                  v-model="content"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 4 -->

                  <!-- Card Desgin 5 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent5" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content5')"
                            aria-expanded="false"
                            aria-controls="collapseContent5"
                            data-toggle="collapse"
                            data-target="#collapseContent5"
                          >
                            Group 5
                            <span
                              id="iconToggleContent5"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent5"
                          class="collapse"
                          aria-labelledby="TabContent5"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview5_1"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5_1 = '';
                                      row.image5_1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_1 ? row.preview5_1 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_1Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line5_1"
                                />
                                <vue-editor
                                  id="editorMask5_1"
                                  v-model="content"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color5_1"
                                />
                              </div>
                              <div class="form-group col-12">
                                <vue-editor
                                  id="editorBody5_1"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line5_2"
                                />
                                <vue-editor
                                  id="editorMask5_2"
                                  v-model="content"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color5_2"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview5_2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5_2 = '';
                                      row.image5_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_2 ? row.preview5_2 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_2Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-12">
                                <vue-editor
                                  id="editorBody5_2"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview5_3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5_3 = '';
                                      row.image5_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_3 ? row.preview5_3 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_3Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line5_3"
                                />
                                <vue-editor
                                  id="editorMask5_3"
                                  v-model="content"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color5_3"
                                />
                              </div>
                              <div class="form-group col-12">
                                <vue-editor
                                  id="editorBody5_3"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-5" />

                            <div class="row col-12 mt-5" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 45px"
                                  v-model="row.line5_4"
                                />
                                <vue-editor
                                  id="editorMask5_4"
                                  v-model="content"
                                />
                                <input
                                  class="form-control"
                                  type="color"
                                  style="height: 50px"
                                  v-model="row.color5_4"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview5_4"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview5_4 = '';
                                      row.image5_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_4 ? row.preview5_4 : ''"
                                  style="width: 100%; height: 225px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_4Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-12">
                                <vue-editor
                                  id="editorBody5_4"
                                  v-model="content"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 5 -->

                  <!-- Card Desgin 6 -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent6" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content6')"
                            aria-expanded="false"
                            aria-controls="collapseContent6"
                            data-toggle="collapse"
                            data-target="#collapseContent6"
                          >
                            Group 6
                            <span
                              id="iconToggleContent6"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent6"
                          class="collapse"
                          aria-labelledby="TabContent6"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody6_1"
                                  v-model="content"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview6_1"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview6_1 = '';
                                      row.image6_1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview6_1 ? row.preview6_1 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage6_1Change"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview6_2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview6_2 = '';
                                      row.image6_2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview6_2 ? row.preview6_2 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage6_2Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody6_2"
                                  v-model="content"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody6_3"
                                  v-model="content"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview6_3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview6_3 = '';
                                      row.image6_3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview6_3 ? row.preview6_3 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage6_3Change"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                            <hr class="mt-10" />

                            <div class="row col-12 mt-10" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="">
                                  <button
                                    v-if="row.preview6_4"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview6_4 = '';
                                      row.image6_4 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview6_4 ? row.preview6_4 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage6_4Change"
                                  accept="image/*"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="editorBody6_4"
                                  v-model="content"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Desgin 6 -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Create Shortcut</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "create-comp",

  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
       editor: VueEditor,
      content: "<h1>Type your content here!</h1>",
      auth: {
        access_token: "",
      },
      row: {
        // image
        preview: "",
        image: "",

        // mask
        bgTitle: "",
        bgColor: "",

        // pg Name
        title: "",
        slug: "",
        sort: 0,
        status: 1,
        has_faq: 0,
        has_programs: 0,
        has_application: 0,
        has_download: 0,

        download_name: "",
        download_image_preview: "",
        download_image: "",
        download_preview: "",
        download_file: "",

        application_name: "",
        application_path: "",

        // F@King design
        // D1
        body1_1: "",

        preview1_2: "",
        image1_2: "",
        line1_2: "",
        mask1_2: "",
        color1_2: "",
        body1_2_1: "",
        body1_2_2: "",

        preview1_3: "",
        image1_3: "",
        line1_3: "",
        mask1_3: "",
        color1_3: "",
        body1_3_1: "",
        body1_3_2: "",

        preview1_4: "",
        image1_4: "",
        line1_4: "",
        mask1_4: "",
        color1_4: "",
        body1_4_1: "",
        body1_4_2: "",

        preview1_5: "",
        image1_5: "",
        line1_5: "",
        mask1_5: "",
        color1_5: "",
        body1_5_1: "",
        body1_5_2: "",

        // d2
        preview2_1: "",
        image2_1: "",
        line2_1: "",
        mask2_1: "",
        color2_1: "",
        body2_1: "",

        preview2_2: "",
        image2_2: "",
        line2_2: "",
        mask2_2: "",
        color2_2: "",
        body2_2: "",

        preview2_3: "",
        image2_3: "",
        line2_3: "",
        mask2_3: "",
        color2_3: "",
        body2_3: "",

        preview2_4: "",
        image2_4: "",
        line2_4: "",
        mask2_4: "",
        color2_4: "",
        body2_4: "",

        // d3
        preview3_1: "",
        image3_1: "",
        body3_1: "",

        preview3_2: "",
        image3_2: "",
        body3_2: "",

        preview3_3: "",
        image3_3: "",
        body3_3: "",

        preview3_4: "",
        image3_4: "",
        body3_4: "",

        preview3_5: "",
        image3_5: "",
        body3_5: "",

        preview3_6: "",
        image3_6: "",
        body3_6: "",

        preview3_7: "",
        image3_7: "",
        body3_7: "",

        preview3_8: "",
        image3_8: "",
        body3_8: "",

        preview3_9: "",
        image3_9: "",
        body3_9: "",

        // d4
        preview4_1: "",
        image4_1: "",
        body4_1: "",

        preview4_2: "",
        image4_2: "",
        body4_2: "",

        preview4_3: "",
        image4_3: "",
        body4_3: "",

        preview4_4: "",
        image4_4: "",
        body4_4: "",

        // d5
        preview5_1: "",
        image5_1: "",
        line5_1: "",
        mask5_1: "",
        color5_1: "",
        body5_1: "",

        preview5_2: "",
        image5_2: "",
        line5_2: "",
        mask5_2: "",
        color5_2: "",
        body5_2: "",

        preview5_3: "",
        image5_3: "",
        line5_3: "",
        mask5_3: "",
        color5_3: "",
        body5_3: "",

        preview5_4: "",
        image5_4: "",
        line5_4: "",
        mask5_4: "",
        color5_4: "",
        body5_4: "",

        // d6
        preview6_1: "",
        image6_1: "",
        body6_1: "",

        preview6_2: "",
        image6_2: "",
        body6_2: "",

        preview6_3: "",
        image6_3: "",
        body6_3: "",

        preview6_4: "",
        image6_4: "",
        body6_4: "",
      },

      croppieImage: "",
      cropped: false,
      root_id: "",
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    if (this.$route.params.root_id) {
      this.root_id = this.$route.params.root_id;
    }
  },
  methods: {
    // Add New
    addNew() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/searchs",
        method: "POST",
        data: {
          parent_id: this.root_id,
          // image
          image: this.row.image,

          // mask
          bgTitle: this.row.bgTitle,
          bgColor: this.row.bgColor,

          // pgName
          title: this.row.title,
          slug: this.row.slug,
          sort: this.row.sort,
          status: this.row.status,
          has_faq: this.row.has_faq,
          has_programs: this.row.has_programs,
          has_application: this.row.has_application,
          has_download: this.row.has_download,

          download_name: this.row.download_name,
          download_image: this.row.download_image,
          download_file: this.row.download_file,

          application_name: this.row.application_name,
          application_path: this.row.application_path,

          // d1
          body1_1: this.row.body1_1,

          image1_2: this.row.image1_2,
          line1_2: this.row.line1_2,
          mask1_2: this.row.mask1_2,
          color1_2: this.row.color1_2,
          body1_2_1: this.row.body1_2_1,
          body1_2_2: this.row.body1_2_2,

          image1_3: this.row.image1_3,
          line1_3: this.row.line1_3,
          mask1_3: this.row.mask1_3,
          color1_3: this.row.color1_3,
          body1_3_1: this.row.body1_3_1,
          body1_3_2: this.row.body1_3_2,

          image1_4: this.row.image1_4,
          line1_4: this.row.line1_4,
          mask1_4: this.row.mask1_4,
          color1_4: this.row.color1_4,
          body1_4_1: this.row.body1_4_1,
          body1_4_2: this.row.body1_4_2,

          image1_5: this.row.image1_5,
          line1_5: this.row.line1_5,
          mask1_5: this.row.mask1_5,
          color1_5: this.row.color1_5,
          body1_5_1: this.row.body1_5_1,
          body1_5_2: this.row.body1_5_2,

          // d2
          image2_1: this.row.image2_1,
          line2_1: this.row.line2_1,
          mask2_1: this.row.mask2_1,
          color2_1: this.row.color2_1,
          body2_1: this.row.body2_1,

          image2_2: this.row.image2_2,
          line2_2: this.row.line2_2,
          mask2_2: this.row.mask2_2,
          color2_2: this.row.color2_2,
          body2_2: this.row.body2_2,

          image2_3: this.row.image2_3,
          line2_3: this.row.line2_3,
          mask2_3: this.row.mask2_3,
          color2_3: this.row.color2_3,
          body2_3: this.row.body2_3,

          image2_4: this.row.image2_4,
          line2_4: this.row.line2_4,
          mask2_4: this.row.mask2_4,
          color2_4: this.row.color2_4,
          body2_4: this.row.body2_4,

          // d3
          image3_1: this.row.image3_1,
          body3_1: this.row.body3_1,

          image3_2: this.row.image3_2,
          body3_2: this.row.body3_2,

          image3_3: this.row.image3_3,
          body3_3: this.row.body3_3,

          image3_4: this.row.image3_4,
          body3_4: this.row.body3_4,

          image3_5: this.row.image3_5,
          body3_5: this.row.body3_5,

          image3_6: this.row.image3_6,
          body3_6: this.row.body3_6,

          image3_7: this.row.image3_7,
          body3_7: this.row.body3_7,

          image3_8: this.row.image3_8,
          body3_8: this.row.body3_8,

          image3_9: this.row.image3_9,
          body3_9: this.row.body3_9,

          // d4
          image4_1: this.row.image4_1,
          body4_1: this.row.body4_1,

          image4_2: this.row.image4_2,
          body4_2: this.row.body4_2,

          image4_3: this.row.image4_3,
          body4_3: this.row.body4_3,

          image4_4: this.row.image4_4,
          body4_4: this.row.body4_4,

          // d5
          image5_1: this.row.image5_1,
          line5_1: this.row.line5_1,
          mask5_1: this.row.mask5_1,
          color5_1: this.row.color5_1,
          body5_1: this.row.body5_1,

          image5_2: this.row.image5_2,
          line5_2: this.row.line5_2,
          mask5_2: this.row.mask5_2,
          color5_2: this.row.color5_2,
          body5_2: this.row.body5_2,

          image5_3: this.row.image5_3,
          line5_3: this.row.line5_3,
          mask5_3: this.row.mask5_3,
          color5_3: this.row.color5_3,
          body5_3: this.row.body5_3,

          image5_4: this.row.image5_4,
          line5_4: this.row.line5_4,
          mask5_4: this.row.mask5_4,
          color5_4: this.row.color5_4,
          body5_4: this.row.body5_4,

          // d6
          image6_1: this.row.image6_1,
          body6_1: this.row.body6_1,

          image6_2: this.row.image6_2,
          body6_2: this.row.body6_2,

          image6_3: this.row.image6_3,
          body6_3: this.row.body6_3,

          image6_4: this.row.image6_4,
          body6_4: this.row.body6_4,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Added Successfully.",
          });
          this.$router.push({
            name: "searchs-sub",
            params: { root_id: this.root_id },
          });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Title
    onTitleChange() {
      this.onSlugChange(this.row.title);
    },

    // on Paste
    onSlugPaste() {
      let str = this.row.slug;
      this.onSlugChange(str);
    },
    onSlugChange(str) {
      if (!str) {
        let str = this.row.slug;
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      } else {
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      }
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    // d1
    onImage1_2Change(e) {
      const file = e.target.files[0];
      this.row.preview1_2 = URL.createObjectURL(file);
      this.createBase64Image1_2(file);
    },
    createBase64Image1_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_2 = e.target.result;
      };
    },
    onImage1_3Change(e) {
      const file = e.target.files[0];
      this.row.preview1_3 = URL.createObjectURL(file);
      this.createBase64Image1_3(file);
    },
    createBase64Image1_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_3 = e.target.result;
      };
    },
    onImage1_4Change(e) {
      const file = e.target.files[0];
      this.row.preview1_4 = URL.createObjectURL(file);
      this.createBase64Image1_4(file);
    },
    createBase64Image1_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_4 = e.target.result;
      };
    },
    onImage1_5Change(e) {
      const file = e.target.files[0];
      this.row.preview1_5 = URL.createObjectURL(file);
      this.createBase64Image1_5(file);
    },
    createBase64Image1_5(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_5 = e.target.result;
      };
    },

    // d2
    onImage2_1Change(e) {
      const file = e.target.files[0];
      this.row.preview2_1 = URL.createObjectURL(file);
      this.createBase64Image2_1(file);
    },
    createBase64Image2_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_1 = e.target.result;
      };
    },
    onImage2_2Change(e) {
      const file = e.target.files[0];
      this.row.preview2_2 = URL.createObjectURL(file);
      this.createBase64Image2_2(file);
    },
    createBase64Image2_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_2 = e.target.result;
      };
    },
    onImage2_3Change(e) {
      const file = e.target.files[0];
      this.row.preview2_3 = URL.createObjectURL(file);
      this.createBase64Image2_3(file);
    },
    createBase64Image2_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_3 = e.target.result;
      };
    },
    onImage2_4Change(e) {
      const file = e.target.files[0];
      this.row.preview2_4 = URL.createObjectURL(file);
      this.createBase64Image2_4(file);
    },
    createBase64Image2_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2_4 = e.target.result;
      };
    },

    // d3
    onImage3_1Change(e) {
      const file = e.target.files[0];
      this.row.preview3_1 = URL.createObjectURL(file);
      this.createBase64Image3_1(file);
    },
    createBase64Image3_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_1 = e.target.result;
      };
    },
    onImage3_2Change(e) {
      const file = e.target.files[0];
      this.row.preview3_2 = URL.createObjectURL(file);
      this.createBase64Image3_2(file);
    },
    createBase64Image3_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_2 = e.target.result;
      };
    },
    onImage3_3Change(e) {
      const file = e.target.files[0];
      this.row.preview3_3 = URL.createObjectURL(file);
      this.createBase64Image3_3(file);
    },
    createBase64Image3_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_3 = e.target.result;
      };
    },
    onImage3_4Change(e) {
      const file = e.target.files[0];
      this.row.preview3_4 = URL.createObjectURL(file);
      this.createBase64Image3_4(file);
    },
    createBase64Image3_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_4 = e.target.result;
      };
    },
    onImage3_5Change(e) {
      const file = e.target.files[0];
      this.row.preview3_5 = URL.createObjectURL(file);
      this.createBase64Image3_5(file);
    },
    createBase64Image3_5(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_5 = e.target.result;
      };
    },
    onImage3_6Change(e) {
      const file = e.target.files[0];
      this.row.preview3_6 = URL.createObjectURL(file);
      this.createBase64Image3_6(file);
    },
    createBase64Image3_6(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_6 = e.target.result;
      };
    },
    onImage3_7Change(e) {
      const file = e.target.files[0];
      this.row.preview3_7 = URL.createObjectURL(file);
      this.createBase64Image3_7(file);
    },
    createBase64Image3_7(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_7 = e.target.result;
      };
    },

    onImage3_8Change(e) {
      const file = e.target.files[0];
      this.row.preview3_8 = URL.createObjectURL(file);
      this.createBase64Image3_8(file);
    },
    createBase64Image3_8(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_8 = e.target.result;
      };
    },
    onImage3_9Change(e) {
      const file = e.target.files[0];
      this.row.preview3_9 = URL.createObjectURL(file);
      this.createBase64Image3_9(file);
    },
    createBase64Image3_9(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3_9 = e.target.result;
      };
    },

    // d4
    onImage4_1Change(e) {
      const file = e.target.files[0];
      this.row.preview4_1 = URL.createObjectURL(file);
      this.createBase64Image4_1(file);
    },
    createBase64Image4_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image4_1 = e.target.result;
      };
    },
    onImage4_2Change(e) {
      const file = e.target.files[0];
      this.row.preview4_2 = URL.createObjectURL(file);
      this.createBase64Image4_2(file);
    },
    createBase64Image4_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image4_2 = e.target.result;
      };
    },
    onImage4_3Change(e) {
      const file = e.target.files[0];
      this.row.preview4_3 = URL.createObjectURL(file);
      this.createBase64Image4_3(file);
    },
    createBase64Image4_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image4_3 = e.target.result;
      };
    },
    onImage4_4Change(e) {
      const file = e.target.files[0];
      this.row.preview4_4 = URL.createObjectURL(file);
      this.createBase64Image4_4(file);
    },
    createBase64Image4_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image4_4 = e.target.result;
      };
    },
    //d5
    onImage5_1Change(e) {
      const file = e.target.files[0];
      this.row.preview5_1 = URL.createObjectURL(file);
      this.createBase64Image5_1(file);
    },
    createBase64Image5_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_1 = e.target.result;
      };
    },
    onImage5_2Change(e) {
      const file = e.target.files[0];
      this.row.preview5_2 = URL.createObjectURL(file);
      this.createBase64Image5_2(file);
    },
    createBase64Image5_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_2 = e.target.result;
      };
    },
    onImage5_3Change(e) {
      const file = e.target.files[0];
      this.row.preview5_3 = URL.createObjectURL(file);
      this.createBase64Image5_3(file);
    },
    createBase64Image5_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_3 = e.target.result;
      };
    },
    onImage5_4Change(e) {
      const file = e.target.files[0];
      this.row.preview5_4 = URL.createObjectURL(file);
      this.createBase64Image5_4(file);
    },
    createBase64Image5_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_4 = e.target.result;
      };
    },
    // d6
    onImage6_1Change(e) {
      const file = e.target.files[0];
      this.row.preview6_1 = URL.createObjectURL(file);
      this.createBase64Image6_1(file);
    },
    createBase64Image6_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image6_1 = e.target.result;
      };
    },
    onImage6_2Change(e) {
      const file = e.target.files[0];
      this.row.preview6_2 = URL.createObjectURL(file);
      this.createBase64Image6_2(file);
    },
    createBase64Image6_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image6_2 = e.target.result;
      };
    },
    onImage6_3Change(e) {
      const file = e.target.files[0];
      this.row.preview6_3 = URL.createObjectURL(file);
      this.createBase64Image6_3(file);
    },
    createBase64Image6_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image6_3 = e.target.result;
      };
    },
    onImage6_4Change(e) {
      const file = e.target.files[0];
      this.row.preview6_4 = URL.createObjectURL(file);
      this.createBase64Image6_4(file);
    },
    createBase64Image6_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image6_4 = e.target.result;
      };
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.row.download_preview = URL.createObjectURL(file);
      this.createBase64File(file);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_file = e.target.result;
      };
    },

    onFilePreviewChange(e) {
      const file = e.target.files[0];
      this.row.download_image_preview = URL.createObjectURL(file);
      this.createBase64FilePreview(file);
    },
    createBase64FilePreview(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_image = e.target.result;
      };
    },

    // active status
    onStatus() {
      if (this.row.status) this.row.status = 0;
      else this.row.status = 1;
    },
    onFaq() {
      if (this.row.has_faq) this.row.has_faq = 0;
      else this.row.has_faq = 1;
    },
    onPrograms() {
      if (this.row.has_programs) this.row.has_programs = 0;
      else this.row.has_programs = 1;
    },
    onApplication() {
      if (this.row.has_application) this.row.has_application = 0;
      else this.row.has_application = 1;
    },
    onDownload() {
      if (this.row.has_download) this.row.has_download = 0;
      else this.row.has_download = 1;
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({
          name: "searchs-sub",
          params: { root_id: this.root_id },
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
