/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Email Templates</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Email Templates
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="spinner-grow mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Loading -->

          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Forget -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview')"
                            aria-expanded="false"
                            aria-controls="collapseOverview"
                            data-toggle="collapse"
                            data-target="#collapseOverview"
                          >
                            Forget Template
                            <span
                              id="iconToggleOverview"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview"
                          class="collapse"
                          aria-labelledby="TabOverview"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input1"
                                rows="5"
                                v-model="row.fbody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input2"
                                rows="5"
                                v-model="row.fbody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input3"
                                rows="5"
                                v-model="row.fbody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input4"
                                rows="5"
                                v-model="row.fbody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Forget -->

                  <!-- Card Reset -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview2')"
                            aria-expanded="false"
                            aria-controls="collapseOverview2"
                            data-toggle="collapse"
                            data-target="#collapseOverview2"
                          >
                            Reset Template
                            <span
                              id="iconToggleOverview2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview2"
                          class="collapse"
                          aria-labelledby="TabOverview2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input21"
                                rows="5"
                                v-model="row.rbody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input22"
                                rows="5"
                                v-model="row.rbody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input23"
                                rows="5"
                                v-model="row.rbody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input24"
                                rows="5"
                                v-model="row.rbody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Reset -->

                  <!-- Card Verify -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview3')"
                            aria-expanded="false"
                            aria-controls="collapseOverview3"
                            data-toggle="collapse"
                            data-target="#collapseOverview3"
                          >
                            Verify Template
                            <span
                              id="iconToggleOverview3"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview3"
                          class="collapse"
                          aria-labelledby="TabOverview3"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input31"
                                rows="5"
                                v-model="row.vbody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input32"
                                rows="5"
                                v-model="row.vbody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input33"
                                rows="5"
                                v-model="row.vbody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input34"
                                rows="5"
                                v-model="row.vbody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Verify -->

                  <!-- Card Welcome -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview4" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview4')"
                            aria-expanded="false"
                            aria-controls="collapseOverview4"
                            data-toggle="collapse"
                            data-target="#collapseOverview4"
                          >
                            New Register Template
                            <span
                              id="iconToggleOverview4"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview4"
                          class="collapse"
                          aria-labelledby="TabOverview4"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input41"
                                rows="5"
                                v-model="row.wbody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input42"
                                rows="5"
                                v-model="row.wbody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input43"
                                rows="5"
                                v-model="row.wbody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input44"
                                rows="5"
                                v-model="row.wbody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Welcome -->

                  <!-- Card Membership Application -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview5" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview5')"
                            aria-expanded="false"
                            aria-controls="collapseOverview5"
                            data-toggle="collapse"
                            data-target="#collapseOverview5"
                          >
                            Membership Application Template
                            <span
                              id="iconToggleOverview5"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview5"
                          class="collapse"
                          aria-labelledby="TabOverview5"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input51"
                                rows="5"
                                v-model="row.mabody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input52"
                                rows="5"
                                v-model="row.mabody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input53"
                                rows="5"
                                v-model="row.mabody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input54"
                                rows="5"
                                v-model="row.mabody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Membership Application -->

                  <!-- Card Iam instructor Application -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview6" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview6')"
                            aria-expanded="false"
                            aria-controls="collapseOverview6"
                            data-toggle="collapse"
                            data-target="#collapseOverview6"
                          >
                            Iam Instructor Application Template
                            <span
                              id="iconToggleOverview6"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview6"
                          class="collapse"
                          aria-labelledby="TabOverview6"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input61"
                                rows="5"
                                v-model="row.iabody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input62"
                                rows="5"
                                v-model="row.iabody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input63"
                                rows="5"
                                v-model="row.iabody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input64"
                                rows="5"
                                v-model="row.iabody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Iam instructor Application -->

                  <!-- Card Training Program Application -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview7" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview7')"
                            aria-expanded="false"
                            aria-controls="collapseOverview7"
                            data-toggle="collapse"
                            data-target="#collapseOverview7"
                          >
                            Training Program Application Template
                            <span
                              id="iconToggleOverview7"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview7"
                          class="collapse"
                          aria-labelledby="TabOverview7"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input71"
                                rows="5"
                                v-model="row.pabody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input72"
                                rows="5"
                                v-model="row.pabody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input73"
                                rows="5"
                                v-model="row.pabody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input74"
                                rows="5"
                                v-model="row.pabody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Training Program Application -->

                  <!-- Card I've Experience Application -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview8" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview8')"
                            aria-expanded="false"
                            aria-controls="collapseOverview8"
                            data-toggle="collapse"
                            data-target="#collapseOverview8"
                          >
                            I've Experience Application Template
                            <span
                              id="iconToggleOverview8"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview8"
                          class="collapse"
                          aria-labelledby="TabOverview8"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input81"
                                rows="5"
                                v-model="row.habody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input82"
                                rows="5"
                                v-model="row.habody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input83"
                                rows="5"
                                v-model="row.habody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input84"
                                rows="5"
                                v-model="row.habody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card I've Experience Application -->

                  <!-- Card Trianing Center Application -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview9" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview9')"
                            aria-expanded="false"
                            aria-controls="collapseOverview9"
                            data-toggle="collapse"
                            data-target="#collapseOverview9"
                          >
                            Certificate Application Template
                            <span
                              id="iconToggleOverview9"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview9"
                          class="collapse"
                          aria-labelledby="TabOverview9"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input91"
                                rows="5"
                                v-model="row.cabody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input92"
                                rows="5"
                                v-model="row.cabody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input93"
                                rows="5"
                                v-model="row.cabody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input94"
                                rows="5"
                                v-model="row.cabody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Training Center Application -->

                  <!-- Card Accreditation Application -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview10" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview10')"
                            aria-expanded="false"
                            aria-controls="collapseOverview10"
                            data-toggle="collapse"
                            data-target="#collapseOverview10"
                          >
                            Accreditation Application Template
                            <span
                              id="iconToggleOverview9"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview10"
                          class="collapse"
                          aria-labelledby="TabOverview10"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input101"
                                rows="5"
                                v-model="row.aabody1"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input102"
                                rows="5"
                                v-model="row.aabody2"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input103"
                                rows="5"
                                v-model="row.aabody3"
                              >
                              </textarea>
                            </div>

                            <div class="form-group">
                              <textarea
                                class="form-control"
                                id="input104"
                                rows="5"
                                v-model="row.aabody4"
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Accreditation Application -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Email Templates</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";

export default {
  name: "Email",
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        fbody1: "",
        fbody2: "",
        fbody3: "",
        fbody4: "",

        rbody1: "",
        rbody2: "",
        rbody3: "",
        rbody4: "",

        vbody1: "",
        vbody2: "",
        vbody3: "",
        vbody4: "",

        wbody1: "",
        wbody2: "",
        wbody3: "",
        wbody4: "",

        mabody1: "",
        mabody2: "",
        mabody3: "",
        mabody4: "",

        iabody1: "",
        iabody2: "",
        iabody3: "",
        iabody4: "",

        pabody1: "",
        pabody2: "",
        pabody3: "",
        pabody4: "",

        habody1: "",
        habody2: "",
        habody3: "",
        habody4: "",

        cabody1: "",
        cabody2: "",
        cabody3: "",
        cabody4: "",

        aabody1: "",
        aabody2: "",
        aabody3: "",
        aabody4: "",
      },

      pgLoading: false,
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow1();
  },
  methods: {
    fetchRow1() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        //'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/emails/1",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.row.fbody1 = res.data.row.fbody1;
          this.row.fbody2 = res.data.row.fbody2;
          this.row.fbody3 = res.data.row.fbody3;
          this.row.fbody4 = res.data.row.fbody4;

          this.row.rbody1 = res.data.row.rbody1;
          this.row.rbody2 = res.data.row.rbody2;
          this.row.rbody3 = res.data.row.rbody3;
          this.row.rbody4 = res.data.row.rbody4;

          this.row.vbody1 = res.data.row.vbody1;
          this.row.vbody2 = res.data.row.vbody2;
          this.row.vbody3 = res.data.row.vbody3;
          this.row.vbody4 = res.data.row.vbody4;

          this.row.wbody1 = res.data.row.wbody1;
          this.row.wbody2 = res.data.row.wbody2;
          this.row.wbody3 = res.data.row.wbody3;
          this.row.wbody4 = res.data.row.wbody4;

          this.row.mabody1 = res.data.row.mabody1;
          this.row.mabody2 = res.data.row.mabody2;
          this.row.mabody3 = res.data.row.mabody3;
          this.row.mabody4 = res.data.row.mabody4;

          this.row.iabody1 = res.data.row.iabody1;
          this.row.iabody2 = res.data.row.iabody2;
          this.row.iabody3 = res.data.row.iabody3;
          this.row.iabody4 = res.data.row.iabody4;

          this.row.pabody1 = res.data.row.pabody1;
          this.row.pabody2 = res.data.row.pabody2;
          this.row.pabody3 = res.data.row.pabody3;
          this.row.pabody4 = res.data.row.pabody4;

          this.row.habody1 = res.data.row.habody1;
          this.row.habody2 = res.data.row.habody2;
          this.row.habody3 = res.data.row.habody3;
          this.row.habody4 = res.data.row.habody4;

          this.row.cabody1 = res.data.row.cabody1;
          this.row.cabody2 = res.data.row.cabody2;
          this.row.cabody3 = res.data.row.cabody3;
          this.row.cabody4 = res.data.row.cabody4;

          this.row.aabody1 = res.data.row.aabody1;
          this.row.aabody2 = res.data.row.aabody2;
          this.row.aabody3 = res.data.row.aabody3;
          this.row.aabody4 = res.data.row.aabody4;
        })
        .catch(() => {})
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        //'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };

      const options = {
        url: window.baseURL + "/emails/1",
        method: "PUT",
        data: {
          fbody1: this.row.fbody1,
          fbody2: this.row.fbody2,
          fbody3: this.row.fbody3,
          fbody4: this.row.fbody4,

          rbody1: this.row.rbody1,
          rbody2: this.row.rbody2,
          rbody3: this.row.rbody3,
          rbody4: this.row.rbody4,

          vbody1: this.row.vbody1,
          vbody2: this.row.vbody2,
          vbody3: this.row.vbody3,
          vbody4: this.row.vbody4,

          wbody1: this.row.wbody1,
          wbody2: this.row.wbody2,
          wbody3: this.row.wbody3,
          wbody4: this.row.wbody4,

          mabody1: this.row.mabody1,
          mabody2: this.row.mabody2,
          mabody3: this.row.mabody3,
          mabody4: this.row.mabody4,

          iabody1: this.row.iabody1,
          iabody2: this.row.iabody2,
          iabody3: this.row.iabody3,
          iabody4: this.row.iabody4,

          pabody1: this.row.pabody1,
          pabody2: this.row.pabody2,
          pabody3: this.row.pabody3,
          pabody4: this.row.pabody4,

          habody1: this.row.habody1,
          habody2: this.row.habody2,
          habody3: this.row.habody3,
          habody4: this.row.habody4,

          cabody1: this.row.cabody1,
          cabody2: this.row.cabody2,
          cabody3: this.row.cabody3,
          cabody4: this.row.cabody4,

          aabody1: this.row.aabody1,
          aabody2: this.row.aabody2,
          aabody3: this.row.aabody3,
          aabody4: this.row.aabody4,
        },
      };
      this.axios(options)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Updated Successfully.",
          });
          //this.$router.push({ name: 'products'})
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
