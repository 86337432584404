/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Settings</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Settings
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <p><br /></p>
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p><br /></p>
            </div>
          </div>
          <!-- End Loading -->

          <!-- Form -->
          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-md-8 col-sm-12">
                                <label
                                  >Large Logo
                                  <button
                                    v-if="row.preview1"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview1 = '';
                                      row.image1 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview1"
                                    :src="row.preview1"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImageChange"
                                />
                                <p><br /></p>
                                <label>Title</label>
                                <vue-editor id="editor01" v-model="row.body1" />
                              </div>

                              <div class="form-group col-md-4 col-sm-12">
                                <label
                                  >Mini Logo
                                  <button
                                    v-if="row.preview2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2 = '';
                                      row.image2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview2"
                                    :src="row.preview2"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage2Change"
                                />
                                <p><br /></p>
                                <label>Mini Title</label>
                                <vue-editor id="editor02" v-model="row.body2" />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label
                                  >Top Links Background
                                  <button
                                    v-if="row.preview3"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview3 = '';
                                      row.image3 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview3"
                                    :src="row.preview3"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage3Change"
                                />
                                <p><br /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->
                </div>
              </div>
            </div>

            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Settings</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
          </form>
          <!-- End Form -->
        </div>
        <!-- End Content Body -->

        <Footer></Footer>
      </div>
      <!-- Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import iziToast from "izitoast";
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  name: "List",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      editor: VueEditor,
      content: "<h1>Write your content here!</h1>",
      auth: {
        access_token: "",
      },
      row: {
        preview1: "",
        image1: "",
        preview2: "",
        image2: "",

        image3: "",
        preview3: "",

        body1: "",
        body2: "",
      },

      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetchRow
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/contacts/5",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.row.preview1 = res.data.row.image;
          this.row.image1 = res.data.row.image;

          this.row.preview2 = res.data.row.image2;
          this.row.image2 = res.data.row.image2;

          this.row.preview3 = res.data.row.image3;
          this.row.image3 = res.data.row.image3;

          this.row.body1 = res.data.row.body1;
          this.row.body2 = res.data.row.body2;
        })
        .catch(() => {})
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/contacts/5",
        method: "PUT",
        data: {
          image: this.row.image1,
          image2: this.row.image2,
          image3: this.row.image3,
          body1: this.row.body1,
          body2: this.row.body2,
        },
      };
      axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Settings Updated Successfully.",
          });
          //this.$router.push({ name: 'home' })
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview1 = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1 = e.target.result;
      };
    },
    onImage2Change(e) {
      const file = e.target.files[0];
      this.row.preview2 = URL.createObjectURL(file);
      this.createBase64Image2(file);
    },
    createBase64Image2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2 = e.target.result;
      };
    },
    onImage3Change(e) {
      const file = e.target.files[0];
      this.row.preview3 = URL.createObjectURL(file);
      this.createBase64Image3(file);
    },
    createBase64Image3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image3 = e.target.result;
      };
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>
