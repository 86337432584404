/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="">
    <!-- Header (Topbar) -->
    <header class="u-header">
      <div class="u-header-left">
        <a class="u-header-logo" href="javascript:;">
          <!--  <img class="u-header-logo__icon" src="/assets/img/dashboard-icon.png">
                    <img class="u-header-logo__text" src="/assets/img/dashboard-logo.png"> -->
          <img
            class="u-header-logo__icon"
            src="/assets/img/dashboard-icon.png"
            style="height: 37px"
            alt=""
          />
          <img
            class="u-header-logo__text"
            src="/assets/svg/dashboard-txt.svg"
            style="height: 45px"
            alt=""
          />
        </a>
      </div>

      <!-- Header Middle Section -->
      <div class="u-header-middle">
        <!-- Sidebar Invoker -->
        <div class="u-header-section">
          <a class="" href="javascript:;" @click="opnNavyBar">
            <span
              class="ti-align-left u-header-invoker__icon u-sidebar-invoker__icon--open"
            >
            </span>
            <span
              class="ti-align-justify u-header-invoker__icon u-sidebar-invoker__icon--close"
            >
            </span>
          </a>
        </div>
        <!-- End Sidebar Invoker -->

        <!-- Header Search -->
        <div class="u-header-section justify-content-sm-start flex-grow-1 py-0">
          <div
            class="u-header-search"
            data-search-mobile-invoker="#headerSearchMobileInvoker"
            data-search-target="#headerSearch"
          >
            <!-- Header Search Invoker (Mobile Mode) -->
            <a
              id="headerSearchMobileInvoker"
              class="u-header-search__mobile-invoker align-items-center"
              href="javascript:;"
            >
              <span class="ti-search"></span>
            </a>
            <!-- End Header Search Invoker (Mobile Mode) -->

            <!-- Header Search Form -->
            <div id="headerSearch" class="u-header-search-form">
              <form @submit.prevent="onSearch" class="w-100">
                <div class="input-group h-100">
                  <button
                    class="btn-link input-group-prepend u-header-search__btn"
                    type="submit"
                  >
                    <span class="ti-search"></span>
                  </button>
                  <input
                    class="form-control u-header-search__field"
                    type="search"
                    v-model="search"
                    placeholder="Type to search…"
                  />
                </div>
              </form>
            </div>
            <!-- End Header Search Form -->
          </div>
        </div>
        <!-- End Header Search -->

        <!-- Messages -->
        <div class="u-header-section">
          <div class="u-header-dropdown dropdown pt-1">
            <router-link
              :to="{ name: 'inbox' }"
              class="u-header-invoker d-flex align-items-center"
            >
              <span class="position-relative">
                <span class="ti-email u-header-invoker__icon"></span>
                <!-- <span class="u-indicator u-indicator-top-right u-indicator-xxs bg-danger"></span> -->
              </span>
            </router-link>
          </div>
        </div>
        <!-- End Messages -->

        <!-- Logs -->
        <!-- <div class="u-header-section">
                    <div class="u-header-dropdown dropdown pt-1">
                        <router-link :to="{ name: 'logs' }" class="u-header-invoker d-flex align-items-center">
                            <span class="position-relative">
                                <span class="ti-brush-alt u-header-invoker__icon"></span> -->
        <!-- <span class="u-indicator u-indicator-top-right u-indicator-xxs bg-danger"></span> -->
        <!--  </span>
                        </router-link>
                    </div>
                </div> -->
        <!-- End Logs -->

        <!-- App Settings -->
        <!-- <div class="u-header-section">
                    <div class="u-header-dropdown dropdown pt-1">
                        <router-link :to="{ name: 'settings' }" class="u-header-invoker d-flex align-items-center">
                          <span class="position-relative">
                                <span class="ti-layout-grid2 u-header-invoker__icon"></span>
                            </span>
                        </router-link>
                    </div>
                </div> -->
        <!-- End App Settings -->

        <!-- User Profile -->
        <div class="u-header-section u-header-section--profile">
          <div class="u-header-dropdown dropdown">
            <a
              class="link-muted d-flex align-items-center"
              href="#"
              role="button"
              id="userProfileInvoker"
              aria-haspopup="true"
              aria-expanded="false"
              data-toggle="dropdown"
              data-offset="0"
            >
              <img
                class="u-header-avatar img-fluid rounded-circle mr-md-3"
                :src="auth.user_image"
                alt="User Profile"
              />
              <span
                class="text-dark d-none d-md-inline-flex align-items-center"
              >
                {{ auth.user_name }}
                <span class="ti-angle-down text-muted ml-4"></span>
              </span>
            </a>

            <div
              class="u-header-dropdown__menu dropdown-menu dropdown-menu-right"
              aria-labelledby="userProfileInvoker"
            >
              <div class="card p-3">
                <div class="card-body p-0">
                  <ul class="list-unstyled mb-0">
                    <li>
                      <router-link
                        :to="{
                          name: 'edit-users',
                          params: { id: auth.user_id },
                        }"
                        class="link-dark"
                        >Update Profile
                      </router-link>
                    </li>
                    <li>
                      <a class="link-dark" href="javascript:;" @click="logout"
                        >Logout</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End User Profile -->
      </div>
      <!-- End Header Middle Section -->
    </header>
    <!-- End Header (Topbar) -->
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      auth: {
        user_id: "",
        user_name: "",
        user_image: "",
        access_token: "",
      },
      search: "",
    };
  },
  mounted() {},
  computed: {},
  created() {
    //
    if (localStorage.getItem("user_id")) {
      this.auth.user_id = localStorage.getItem("user_id");
    }
    if (localStorage.getItem("user_image")) {
      this.auth.user_image = localStorage.getItem("user_image");
    }
    if (localStorage.getItem("user_name")) {
      this.auth.user_name = localStorage.getItem("user_name");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
  },
  methods: {
    //

    onSearch() {
      this.$emit("headerToChild", this.search);
    },

    // Logout
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_image");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      this.$router.push({ name: "login" });
    },

    //
    opnNavyBar() {
      let el = document.querySelector("body");
      let aside = document.querySelector("aside");
      if (el.classList.contains("side-nav-on-action")) {
        el.classList.remove("side-nav-on-action");
        el.classList.remove("sidebar-mobile-closed");
        aside.classList.remove("toggled");
        aside.classList.remove("action");
        aside.classList.remove("mini");
      } else {
        el.classList.add("side-nav-on-action");
        el.classList.remove("sidebar-mobile-closed");
        aside.classList.add("toggled");
        aside.classList.add("action");
        aside.classList.add("mini");
      }
    },
  },
};
</script>
